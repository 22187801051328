import React, { useState, useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'

import { Container, Row, Col, Button } from 'react-bootstrap'
import { Button as AntButton, message } from 'antd'

import '../InvestorOnboarding.scss'
import './Step06InterestedLocations.scss'
import { ArrowRightOutlined, CloseCircleFilled, CloseOutlined } from '@ant-design/icons'

import { ReactComponent as Africa } from '../../../assets/images/on_boarding_imgs/static_locations/Africa.svg'
import { ReactComponent as Asia } from '../../../assets/images/on_boarding_imgs/static_locations/Asia.svg'
import { ReactComponent as Australia } from '../../../assets/images/on_boarding_imgs/static_locations/Australia.svg'
import { ReactComponent as Canada } from '../../../assets/images/on_boarding_imgs/static_locations/Canada.svg'
import { ReactComponent as Europe } from '../../../assets/images/on_boarding_imgs/static_locations/Europe.svg'
import { ReactComponent as Global } from '../../../assets/images/on_boarding_imgs/static_locations/Global.svg'
import { ReactComponent as India } from '../../../assets/images/on_boarding_imgs/static_locations/India.svg'
import { ReactComponent as LatinAmerica } from '../../../assets/images/on_boarding_imgs/static_locations/LatinAmerica.svg'
import { ReactComponent as LocalToMe } from '../../../assets/images/on_boarding_imgs/static_locations/LocalToMe.svg'
import { ReactComponent as Mexico } from '../../../assets/images/on_boarding_imgs/static_locations/Mexico.svg'
import { ReactComponent as US } from '../../../assets/images/on_boarding_imgs/static_locations/US.svg'

import {
  prevStepInvestor,
  nextStepInvestor,
} from '../../../redux/investorFlowControl/investorFlowControlActions'

import { SaveAndExitClickedReset } from '../../../redux/investorFlowControl/investorFlowControlActions'
import {
  updateLocalInvestorUser,
  updateLocalScoutUser,
  fetchScoutUserInfoUpdate,
  fetchScoutUserLogout,
  fetchInvestorUserInfoUpdate,
  fetchInvestorUserLogout,
} from '../../../redux/auth/authActions'
import MultiLocations from '../Components/MultiLocations'

const STATIC_LOCATIONS = [
  { name: 'Local to me', image: <LocalToMe /> },
  { name: 'US', image: <US /> },
  { name: 'Mexico', image: <Mexico /> },
  { name: 'Canada', image: <Canada /> },
  { name: 'India', image: <India /> },
  { name: 'Europe', image: <Europe /> },
  { name: 'Asia', image: <Asia /> },
  { name: 'Latin America', image: <LatinAmerica /> },
  { name: 'Africa', image: <Africa /> },
  { name: 'Australia', image: <Australia /> },
  { name: 'Global', image: <Global /> },
]

const Step06InterestedLocations = ({
  nextStepInvestor,
  prevStepInvestor,
  location_interests,
  updateLocalInvestorUser,
  fetchInvestorUserInfoUpdate,
  onCountinueClick,
}) => {
  const { scout, scoutUser, user, loading } = useSelector(state => state.auth)
  const { saveAndExitClicked } = useSelector(state => state.investorflowControl)
  const dispatch = useDispatch()
  const isEnabled = scout ? scoutUser?.location_interests?.length > 0 : location_interests?.length > 0
  // const [list, setList] = useState(location_interests || [])
  const [location, setLocation] = useState('')
  const [locationErr, setLocationErr] = useState('')
  useEffect(() => {
    if (!scout) {
      var localLocations = [...location_interests]
      localLocations[location_interests?.indexOf(user.location)] = STATIC_LOCATIONS[0]['name']
      updateLocalInvestorUser({ location_interests: localLocations })
    }
  }, [])

  const onChange = val => {
    if (scout) {
      dispatch(updateLocalScoutUser({ location_interests: val }))
    } else {
      updateLocalInvestorUser({ location_interests: val })
    }
  }

  const onClickStaticLocation = loc_name => {
    if ((location_interests || [])?.includes(loc_name)) {
      updateLocalInvestorUser({
        location_interests: [...location_interests?.filter((x, i) => x !== loc_name)],
      })
    } else {
      updateLocalInvestorUser({ location_interests: [...location_interests, loc_name] })
    }
  }

  const scoutOnClick = isNextOrBack => {
    dispatch(
      fetchScoutUserInfoUpdate({
        location_interests: scoutUser?.location_interests,
        onboarding_step: isEnabled ? 4 : 3,
      }),
    )
      .then(success => {
        if (isNextOrBack) {
          if (success) {
            onCountinueClick()
          }
        }
      })
      .then(() => {
        if (saveAndExitClicked) {
          localStorage.clear()
          // history.push('/investor/login')
          window.location.href = '/scout/login'
          dispatch(fetchScoutUserLogout())
        }
      })
  }

  const investorOnClick = isNextOrBack => {
    var localLocations = [...location_interests]
    localLocations[location_interests?.indexOf(STATIC_LOCATIONS[0]['name'])] = user.location
    fetchInvestorUserInfoUpdate({ location_interests: localLocations, onboarding_step: isEnabled ? 6 : 5 })
      .then(success => {
        if (isNextOrBack) {
          if (success) {
            // nextStepInvestor()
            // onCountinueClick()
          } else {
            prevStepInvestor()
          }
        }
      })
      .then(() => {
        if (saveAndExitClicked) {
          localStorage.clear()
          window.location.href = '/investor/login'
          dispatch(fetchInvestorUserLogout())
        }
      })
  }

  // const investorBackOnClick = e => {
  //   investorOnClick(e, false)
  // }
  useEffect(() => {
    if (saveAndExitClicked) {
      if (scout) {
        scoutOnClick(false)
      } else {
        investorOnClick(false)
      }
      dispatch(SaveAndExitClickedReset())
    }
  }, [saveAndExitClicked])

  const investorNextOnClick = e => {
    investorOnClick(true)
    onCountinueClick()
  }

  const scoutNextOnClick = e => {
    scoutOnClick(true)
  }
  return (
    <div className="intrested-locations-wrap">
      {/*** Heading is here ***/}
      <Container className="mb-0">
        <Row>
          <Col md={10} lg={8} className="mx-auto mb-4">
            {scout ? (
              <h4 className="heading-question">
                Where will the startups you refer generally{' '}
                <span className="font-weight-bold">be based?</span>
              </h4>
            ) : (
              <h4 className="heading-question">
                Where would you prefer for the startups to be{' '}
                <span className="font-weight-bold">headquartered</span>?
              </h4>
            )}
          </Col>
        </Row>
      </Container>

      {/*** Body Content is here ***/}
      <Container>
        <Row>
          <Col md={6} className="mx-auto mb-0 ">
            <MultiLocations
              location={location}
              setLocation={data => setLocation(data)}
              onAddLocation={() => {
                // setList([...list, location])
                if (!location) {
                  return
                } else {
                  if (scout) {
                    if (scoutUser?.location_interests?.includes(location)) {
                      setLocationErr('You have already added this location')
                    } else {
                      setLocationErr('')
                      onChange([...scoutUser?.location_interests, location])
                    }
                  } else {
                    if (location_interests?.includes(location)) {
                      message.error('You have already added this location.')
                      return
                    }
                    onChange([...location_interests, location])
                  }
                }
                setLocation('')
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col md={6} className="mx-auto mb-0 ">
            <p className="mr-auto text-danger text-center mt-2">{locationErr}</p>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mx-auto pb-3">
            <div className={scoutUser?.location_interests?.length > 0 && 'locationInterestList'}>
              {(scout ? scoutUser?.location_interests : location_interests || []).map((item, key) => {
                return (
                  <div className="d-flex align-items-center justify-content-between locationRow" key={key}>
                    <div className="locationName">{item}</div>
                    <div
                      className="d-flex align-items-center justify-content-center circle-close"
                      onClick={() => {
                        if (scout) {
                          onChange([...scoutUser?.location_interests?.filter((x, i) => i !== key)])
                        } else {
                          onChange([...location_interests?.filter((x, i) => i !== key)])
                        }
                      }}
                    >
                      <CloseCircleFilled className="close-icon" />
                    </div>
                  </div>
                )
              })}
            </div>
          </Col>
        </Row>
      </Container>
      {!scout && (
        <Container className="mb-0">
          <Row>
            <Col md={9} className="mx-auto my-3 d-flex align-items-center justify-content-center">
              <div className="d-flex align-items-center justify-content-start flex-wrap headquartered-wrapper pl-0">
                {STATIC_LOCATIONS.map(x => (
                  <AntButton
                    className={`d-flex align-items-center m-0 headquartered-btn ${location_interests.includes(x.name) && 'selected-headquartered-btn'
                      }`}
                    type={location_interests.includes(x.name) ? 'primary' : 'default'}
                    onClick={() => onClickStaticLocation(x.name)}
                  >
                    {x.image}
                    <span className="headquartered-btn-text">{x.name}</span>
                  </AntButton>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      )}

      {/* New Back & Continue buttons */}
      <section className="bottomBtns">
        <Container className="">
          <Row>
            <Col md={4} xs={12} className="text-center mx-auto">
              <AntButton
                loading={loading}
                disabled={!isEnabled}
                onClick={scout ? scoutNextOnClick : investorNextOnClick}
                className="continueBtnOnboard btn-block"
              >
                Continue <ArrowRightOutlined />
              </AntButton>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  )
}

const mapStateToProps = state => ({
  stepInvestor: state.investorflowControl.stepInvestor,
  location_interests: state.auth.user.location_interests,
  updateUserInfoFailed: state.investorflowControl.updateUserInfoFailed,
})

const mapStateToDispatch = dispatch => ({
  nextStepInvestor: () => dispatch(nextStepInvestor()),
  prevStepInvestor: () => dispatch(prevStepInvestor()),

  updateLocalInvestorUser: changedProperties => {
    dispatch(updateLocalInvestorUser(changedProperties))
  },

  fetchInvestorUserInfoUpdate: changedProperties => {
    return dispatch(fetchInvestorUserInfoUpdate(changedProperties))
  },
})

export default connect(mapStateToProps, mapStateToDispatch)(Step06InterestedLocations)
