import { message } from 'antd'
import api from '../../utils/api'
import { fetchInvestorUserLogout, RECEIVE_SIGNIN_BY_EMAIL, SET_PROFILE_DATA } from '../auth/authActions'
import * as actionTypes from './ActionTypes'

export const UPDATE_STEP = 'UPDATE_STEP'
export const UPDATE_TAB = 'UPDATE_TAB'
export const LOGIN_FAILED = 'LOGIN_FAILED'
export const SIGNUP_FAILED = 'SIGNUP_FAILED'
export const UPDATE_USER_INFO_FAILED = 'UPDATE_USER_INFO_FAILED'
export const DID_ENTER_REGISTER_PAGE = 'DID_ENTER_REGISTER_PAGE'
export const RECEIVE_USER_PROFILE = 'RECEIVE_USER_PROFILE'
export const EDIT_PHOTO_START = 'EDIT_PHOTO_START'
export const RECEIVE_USER_IMAGE = 'RECEIVE_USER_IMAGE'
export const UPDATE_INDEX = 'UPDATE_INDEX'
export const USER_IMAGE = 'USER_IMAGE'
export const CHANGE_REFER_INFO = 'CHANGE_REFER_INFO'
export const SET_PASS_DEAL_REASON = 'SET_PASS_DEAL_REASON'
export const SET_PASS_DEAL_ISSUE = 'SET_PASS_DEAL_ISSUE'
export const SET_PASS_DEAL_FEEDBACK = 'SET_PASS_DEAL_FEEDBACK'
export const SET_PASS_DEAL_SUBMIT_PAGE = 'SET_PASS_DEAL_SUBMIT_PAGE'
export const SET_US_SSN = 'SET_US_SSN'
export const SET_INVESTMENT_VALUE = 'SET_INVESTMENT_VALUE'
export const SET_INVESTOR_INFO = 'SET_INVESTOR_INFO'
export const SET_INVESTOR_INFO_NO_SSN = 'SET_INVESTOR_INFO_NO_SSN'
export const SET_ACCREDITION_TYPE = 'SET_ACCREDITION_TYPE'
export const SET_INVESTOR_LEGAL_NAME_INFO = 'SET_INVESTOR_LEGAL_NAME_INFO'
export const SET_INVESTOR_BANK_ID = 'SET_INVESTOR_BANK_ID'
export const REMOVE_ACCOUNT_ERROR = 'REMOVE_ACCOUNT_ERROR'
export const REMOVE_POST_MONEY_ERROR = 'REMOVE_POST_MONEY_ERROR'
export const ADD_INVESTING_STEPS_DATA = 'ADD_INVESTING_STEPS_DATA'
export const REMOVE_INVESTING_STEPS_DATA = 'REMOVE_INVESTING_STEPS_DATA'
export const ADD_ALL_INVESTING_STEPS_DATA = 'ADD_ALL_INVESTING_STEPS_DATA'

export const setPassDealReason = text => ({
  type: 'SET_PASS_DEAL_REASON',
  payload: text,
})

export const setPassDealIssue = text => ({
  type: 'SET_PASS_DEAL_ISSUE',
  payload: text,
})

export const setPassDealFeedback = text => ({
  type: 'SET_PASS_DEAL_FEEDBACK',
  payload: text,
})

export const setPassDealAnonymous = text => ({
  type: 'SET_PASS_DEAL_ANONYMOUS',
  payload: text,
})

export const setPassDealSubmitPage = bool => ({
  type: 'SET_PASS_DEAL_SUBMIT_PAGE',
  payload: bool,
})
export const setInvestorName = text => ({
  type: 'SET_INVESTOR_NAME',
  payload: text,
})

export const setUsSSN = bool => ({
  type: 'SET_US_SSN',
  payload: bool,
})

export const clearPassDeal = () => ({
  type: 'CLEAR_PASS_DEAL',
})

export const setConfirmedEmail = () => ({
  type: 'SET_CONFIRMED_EMAIL',
})

export const nextStep = () => ({
  type: 'NEXT_STEP',
})

export const nextStepInvestor = () => ({
  type: 'NEXT_STEP_INVESTOR',
})

export const prevStep = () => ({
  type: 'PREV_STEP',
})

export const prevStepInvestor = () => ({
  type: 'PREV_STEP_INVESTOR',
})

export const initialState = () => ({
  type: 'RESET_INITIAL_STATE',
})

export const toggleLogoToHome = () => ({
  type: 'RETURN_HOME_PAGE',
})

export const setSearchField = text => {
  return { type: 'CHANGE_SEARCHFIELD', payload: text }
}

export const setInputField = text => {
  return { type: 'ANGELGROUP_INPUT', payload: text }
}

export const setSyndicateField = text => {
  return { type: 'ADDED_SYNDICATE_GROUP', payload: text }
}

export const isClickedToShowForm = () => ({
  type: 'SHOW_SYNDICATE_FORM',
})

export const removedSyndicate = () => ({
  type: 'HIDE_SYNDICATE_FORM',
})

export const updateStep = stepInvestor => ({
  type: UPDATE_STEP,
  payload: stepInvestor,
})

export const updateTab = tabInvestor => ({
  type: UPDATE_TAB,
  payload: tabInvestor,
})

export const investorLoginFailed = () => ({
  type: LOGIN_FAILED,
})

export const investorSignupFailed = () => ({
  type: SIGNUP_FAILED,
})

export const updateUserInfoFailed = () => ({
  type: UPDATE_USER_INFO_FAILED,
})

export const didEnterRegisterPage = () => ({
  type: DID_ENTER_REGISTER_PAGE,
})

export const receiveUserProfile = data => ({
  type: RECEIVE_USER_PROFILE,
  payload: data,
})
export const receiveUserImage = data => ({
  type: RECEIVE_USER_IMAGE,
  payload: data,
})

export const updateMondayNotifications = data => ({
  type: 'UPDATE_INV_MONDAY_NOTIFICATIONS',
  payload: data,
})

export const updateShowProfile = data => ({
  type: 'UPDATE_INV_SHOW_PROFILE',
  payload: data,
})

export const updateIndex = () => ({
  type: UPDATE_INDEX,
})

export const setHistorySelection = selection => ({
  type: 'SET_INV_HISTORY_SELECTION',
  payload: selection,
})

export const setDiscoverView = () => ({
  type: 'SET_INV_DISCOVER_VIEW',
})

export const setHistoryView = () => ({
  type: 'SET_INV_HISTORY_VIEW',
})

export const receiveUserHistory = data => ({
  type: 'RECEIVE_INV_USER_HISTORY',
  payload: data,
})

export const receiveUserHistoryConnected = data => ({
  type: 'RECEIVE_INV_USER_HISTORY_CONNECTED',
  payload: data,
})

export const receiveUserHistoryPassed = data => ({
  type: 'RECEIVE_INV_USER_HISTORY_PASSED',
  payload: data,
})

export const setInvitedStartup = data => ({
  type: 'SET_INVITED_STARTUP',
  payload: data,
})

export const removeStartup = () => ({
  type: 'REMOVE_STARTUP',
})

export const loadingDashboard = loading => ({
  type: 'LOAD_INV_DASHBOARD',
  payload: loading,
})

export const receiveUserHistoryCardInfo = data => ({
  type: 'RECEIVE_INV_USER_HISTORY_CARD_INFO',
  payload: data,
})

export const getInvestorDeals = (data, page, totalCount) => ({
  type: 'GET_INVESTOR_DEALS',
  payload: data,
  page,
  totalCount,
})

export const setInvestorLoader = () => ({
  type: 'SET_INVESTOR_LOADER',
})

export const setHideInvestorLoader = () => ({
  type: 'SET_HIDE_INVESTOR_LOADER',
})

export const getInvestorDealsList = (data, page, totalCount) => ({
  type: 'GET_INVESTOR_DEALS_LIST',
  payload: data,
  page,
  totalCount,
})

export const clearInvestorDeals = () => ({
  type: 'CLEAR_INVESTOR_DEALS',
})

export const setHideInvestorLoadButton = data => ({
  type: 'SET_HIDE_INVESTOR_LOAD_BUTTON',
  payload: data,
})

export const getInvestorDealDetail = data => ({
  type: 'GET_INVESTOR_DEAL_DETAIL',
  payload: data,
})

export const setInvestorDealQuestionLoader = data => ({
  type: 'SET_INVESTOR_DEAL_QUESTION_LOADER',
  payload: data,
})

export const getInvestorDealQuestion = data => ({
  type: 'GET_INVESTOR_DEAL_QUESTION',
  payload: data,
})

export const getInvestorMonthlyUpdates = data => ({
  type: 'GET_INVESTOR_MONTHLY_UPDATES',
  payload: data,
})

export const getInvestorMonthlyUpdatesMetricData = data => ({
  type: 'GET_INVESTOR_MONTHLY_UPDATES_METRIC_DATA',
  payload: data,
})

export const getInvestorPassedDeals = (data, count, karma_points) => ({
  type: 'GET_INVESTOR_PASSED_DEALS',
  payload: data,
  totalCount: count,
  karma_points,
})

export const getInvestorKarmaPoints = data => ({
  type: 'GET_INVESTOR_KARMA_POINTS',
  payload: data,
})

export const SaveAndExitClicked = data => ({
  type: 'Save_And_Exit_Clicked',
  payload: data,
})
export const SaveAndExitClickedReset = data => ({
  type: 'Save_And_Exit_Clicked_Reset',
  payload: data,
})

export const setInvestmentValue = data => ({
  type: 'SET_INVESTMENT_VALUE',
  payload: data,
})

export const setInvestorInfo = data => ({
  type: 'SET_INVESTOR_INFO',
  payload: data,
})

export const setInvestorInfoNoSSN = data => ({
  type: 'SET_INVESTOR_INFO_NO_SSN',
  payload: data,
})

export const setInvestorLegalNameInfo = data => ({
  type: 'SET_INVESTOR_LEGAL_NAME_INFO',
  payload: data,
})

export const setInvestorBankId = data => ({
  type: 'SET_INVESTOR_BANK_ID',
  payload: data,
})

export const setAccreditionType = data => ({
  type: 'SET_ACCREDITION_TYPE',
  payload: data,
})
export const setLocalAccreditionLevel = data => ({
  type: 'SET_LOCAL_ACCREDITION_LEVEL',
  payload: data,
})

export const removeAccountError = () => ({
  type: 'REMOVE_ACCOUNT_ERROR',
})

export const removePostMoneyError = () => ({
  type: 'REMOVE_POST_MONEY_ERROR',
})

export const emptyBusinessCategories = () => ({
  type: 'EMPTY_BUSINESS_CATEGORIES',
})

export const isDoneStatus = () => {
  return async dispatch => {
    dispatch({
      type: actionTypes.DONE_SUCCESS,
    })
  }
}

export const isDebounceLoader = data => {
  return async dispatch => {
    dispatch({
      type: actionTypes.IS_DEBOUNCE_LOADER,
      payload: data,
    })
  }
}

export const setTaxInformationData = data => {
  return async dispatch => {
    dispatch({
      type: actionTypes.TAX_INFORMATION_DATA,
      payload: data,
    })
  }
}

export const setCurrent = data => {
  return async dispatch => {
    dispatch({
      type: actionTypes.SET_CURRENT,
      payload: data,
    })
  }
}

export const getCompletedTour = () => {
  return async dispatch => {
    dispatch({
      type: actionTypes.GET_COMPLETED_TOUR_INIT,
    })
    await api
      .get(`${process.env.REACT_APP_API_URL}/investor/tour-completed`)
      .then(res => {
        dispatch({
          type: actionTypes.GET_COMPLETED_TOUR_SUCCESS,
          payload: res,
        })
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.GET_COMPLETED_TOUR_FAILED,
        })
        throw error?.response?.data?.error || error?.message
      })
  }
}

export const getTourTokenData = data => {
  return async dispatch => {
    dispatch({
      type: actionTypes.GET_TOUR_TOKEN_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/investor/login`, data)
      .then(res => {
        dispatch({
          type: actionTypes.GET_TOUR_TOKEN_SUCCESS,
          payload: res,
        })
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.GET_TOUR_TOKEN_FAILED,
        })
        throw error?.response?.data?.error || error?.message
      })
  }
}

export const fetchUserDashboard = () => {
  return dispatch => {
    dispatch(loadingDashboard(true))
    return api
      .get(`${process.env.REACT_APP_API_URL}/investor/dashboard`, undefined)
      .then(res => {
        if (res.result) {
          dispatch(receiveUserProfile(res.data))
          dispatch(loadingDashboard(false))
          return true
        } else {
          dispatch(loadingDashboard(false))
          if (res.error === 'token expired' && res.is_expired) {
            dispatch(fetchInvestorUserLogout())
            dispatch({
              type: 'SET_AUTH_EXPIRED',
            })
          }
          return false
        }
      })
      .catch(() => dispatch(loadingDashboard(false)))
  }
}

export const completeKyc = (data, onSuccess) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.GET_CUSTOMER_INFO_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/investor/kyc-complete`, {
        inquiry_id: data,
      })
      .then(res => {
        if (res.result) {
          onSuccess(res)
        }
        return res
      })
      .catch(error => {
        throw error?.response?.data?.error || error?.message
      })
  }
}

export const fetchUserProfile = offsetVal => {
  return dispatch => {
    return api.get(`${process.env.REACT_APP_API_URL}/startup/get-users/` + offsetVal, undefined).then(res => {
      let data = res.data
      if (res.result) {
        dispatch(receiveUserProfile(data))
        return true
      } else {
        if (res.error === 'token expired' && res.is_expired) {
          dispatch(fetchInvestorUserLogout())
          dispatch({
            type: 'SET_AUTH_EXPIRED',
          })
        }
        return false
      }
    })
  }
}

export const fetchCompanyLogoFromCompanyUrl = company_url => {
  return dispatch => {
    dispatch({
      type: 'SET_COMPANY_LOGO_LOADING',
      payload: true,
    })
    return api
      .get(`${process.env.REACT_APP_API_URL}/investor/company-logo-url/` + company_url, undefined)
      .then(response_json => {
        // Check that the url exists
        if (response_json.error) {
          dispatch({
            type: USER_IMAGE,
            payload: 'none found',
          })
          dispatch({
            type: 'SET_COMPANY_LOGO_LOADING',
            payload: false,
          })
          return false
        } else {
          dispatch({
            type: USER_IMAGE,
            payload: response_json.company_logo_url,
          })
          dispatch({
            type: 'SET_COMPANY_LOGO_LOADING',
            payload: false,
          })
          return true
        }
      })
  }
}

export const editPhotoStart = () => {
  return {
    type: EDIT_PHOTO_START,
  }
}

export const editPhoto = file => {
  return dispatch => {
    dispatch(editPhotoStart())
    return api.sendFile(`${process.env.REACT_APP_API_URL}/investor/mime-files`, file, 'image').then(res => {
      if (res.result) {
        dispatch(receiveUserImage(res.url))
        return true
      } else {
        if (res.error === 'token expired' && res.is_expired) {
          dispatch(fetchInvestorUserLogout())
          dispatch({
            type: 'SET_AUTH_EXPIRED',
          })
        }
        return false
      }
    })
  }
}

export const sendReferLink = email => {
  return dispatch => {
    return api
      .post(`${process.env.REACT_APP_API_URL}/investor/referral-link`, {
        email,
      })
      .then(res => {
        if (res.result) {
          dispatch({
            type: 'CHANGE_REFER_INFO',
          })
          return res
        } else if (res.error === 'user exists') {
          return 'That friend already has an account with us!'
        } else {
          if (res.error === 'token expired' && res.is_expired) {
            dispatch(fetchInvestorUserLogout())
            dispatch({
              type: 'SET_AUTH_EXPIRED',
            })
          }
          return false
        }
      })
  }
}

export const sendMondayNote = monday_note => {
  return dispatch => {
    return api
      .post(`${process.env.REACT_APP_API_URL}/investor/monday-notifications`, monday_note)
      .then(res => {
        if (res.result) {
          dispatch(updateMondayNotifications(monday_note))
        } else {
          if (res.error === 'token expired' && res.is_expired) {
            dispatch(fetchInvestorUserLogout())
            dispatch({
              type: 'SET_AUTH_EXPIRED',
            })
          }
          return false
        }
      })
  }
}

export const displayProfile = display_profile => {
  return dispatch => {
    return api
      .post(`${process.env.REACT_APP_API_URL}/investor/profile-visibility`, display_profile)
      .then(res => {
        if (res.result) {
          dispatch(updateShowProfile(display_profile))
        } else {
          if (res.error === 'token expired' && res.is_expired) {
            dispatch(fetchInvestorUserLogout())
            dispatch({
              type: 'SET_AUTH_EXPIRED',
            })
          }
          return false
        }
      })
  }
}

export const changePassword = () => {
  return dispatch => {
    return api.get(`${process.env.REACT_APP_API_URL}/investor/change-password`).then(res => {
      if (res.result) {
        dispatch(fetchInvestorUserLogout())
      } else {
        if (res.error === 'token expired' && res.is_expired) {
          dispatch(fetchInvestorUserLogout())
          dispatch({
            type: 'SET_AUTH_EXPIRED',
          })
        }
        return false
      }
    })
  }
}

export const verifyPassword = pw => {
  return dispatch => {
    return api
      .post(`${process.env.REACT_APP_API_URL}/investor/verify-password`, {
        password: pw,
      })
      .then(res => {
        if (res.result) {
          return res.result
        } else {
          if (res.error === 'token expired' && res.is_expired) {
            dispatch(fetchInvestorUserLogout())
            dispatch({
              type: 'SET_AUTH_EXPIRED',
            })
          }
          return false
        }
      })
  }
}

export const deleteAccount = pw => {
  return dispatch => {
    return api
      .post(`${process.env.REACT_APP_API_URL}/investor/delete-account`, {
        delete: true,
      })
      .then(res => {
        if (res.result) {
          dispatch(fetchInvestorUserLogout())
          return true
        } else {
          if (res.error === 'token expired' && res.is_expired) {
            dispatch(fetchInvestorUserLogout())
            dispatch({
              type: 'SET_AUTH_EXPIRED',
            })
          }
          return false
        }
      })
  }
}

export const fetchUserHistoryAll = () => {
  return dispatch => {
    return api.get(`${process.env.REACT_APP_API_URL}/investor/history-all`, undefined).then(res => {
      if (res.result) {
        dispatch(receiveUserHistory(res.data))
        return res.data
      } else {
        if (res.error === 'token expired' && res.is_expired) {
          dispatch(fetchInvestorUserLogout())
          dispatch({
            type: 'SET_AUTH_EXPIRED',
          })
        }
        return false
      }
    })
  }
}

export const fetchUserHistoryConnected = () => {
  return dispatch => {
    return api.get(`${process.env.REACT_APP_API_URL}/investor/history-connected`, undefined).then(res => {
      if (res.result) {
        dispatch(receiveUserHistoryConnected(res.data))
        return res.data
      } else {
        if (res.error === 'token expired' && res.is_expired) {
          dispatch(fetchInvestorUserLogout())
          dispatch({
            type: 'SET_AUTH_EXPIRED',
          })
        }
        return false
      }
    })
  }
}

export const fetchUserHistoryPassed = () => {
  return dispatch => {
    return api.get(`${process.env.REACT_APP_API_URL}/investor/history-passed`, undefined).then(res => {
      if (res.result) {
        dispatch(receiveUserHistoryPassed(res.data))
        return res.data
      } else {
        if (res.error === 'token expired' && res.is_expired) {
          dispatch(fetchInvestorUserLogout())
          dispatch({
            type: 'SET_AUTH_EXPIRED',
          })
        }
        return false
      }
    })
  }
}

export const handlePassInviteStartup = (status, id, fields, comment, is_anonymous) => {
  let body = {
    invite: status,
    user_id: id,
  }

  if (!status) {
    body['feedback'] = {
      fields: [fields],
      comment,
      is_anonymous,
    }
  }

  return dispatch => {
    return api.post(`${process.env.REACT_APP_API_URL}/investor/dashboard`, body).then(res => {
      if (res) {
        dispatch(fetchUserHistoryAll())
        dispatch(fetchUserHistoryPassed())
        dispatch(fetchUserHistoryConnected())
        if (res.message === 'connected') {
          dispatch({
            type: 'SET_CONNECTED_STARTUP',
            payload: true,
          })
        } else {
          dispatch({
            type: 'SET_CONNECTED_STARTUP',
            payload: false,
          })
        }

        return res
      } else {
        if (res.error === 'token expired' && res.is_expired) {
          dispatch(fetchInvestorUserLogout())
          dispatch({
            type: 'SET_AUTH_EXPIRED',
          })
        }
        return false
      }
    })
  }
}

export const fetchUserHistoryCardInfo = id => {
  return dispatch => {
    return api
      .post(`${process.env.REACT_APP_API_URL}/investor/startup-detail`, {
        id,
      })
      .then(res => {
        if (res.result) {
          dispatch(receiveUserHistoryCardInfo(res.data))
          return res.data
        } else {
          if (res.error === 'token expired' && res.is_expired) {
            dispatch(fetchInvestorUserLogout())
            dispatch({
              type: 'SET_AUTH_EXPIRED',
            })
          }
          return false
        }
      })
  }
}

export const fetchInvestorDeals = (data, type) => {
  return dispatch => {
    dispatch(setInvestorLoader())
    return api
      .post(`${process.env.REACT_APP_API_URL}/investor/get-relevant-startups`, {
        page_num: data,
        page_size: 10,
        deals_type: type,
      })
      .then(res => {
        if (res.result) {
          if (!res?.data?.length) {
            dispatch(setHideInvestorLoadButton(true))
          } else {
            dispatch(setHideInvestorLoadButton(false))
          }
          if (data === 1) {
            dispatch(getInvestorDealsList(res.data, data + 1, res.total_count))
          } else {
            dispatch(getInvestorDeals(res.data, data + 1, res.total_count))
          }
          return res
        } else {
          dispatch(setHideInvestorLoader())
          if (res.error === 'token expired' && res.is_expired) {
            dispatch(fetchInvestorUserLogout())
            dispatch({
              type: 'SET_AUTH_EXPIRED',
            })
          }
          return false
        }
      })
  }
}

export const investorPassDeal = (data, onSuccess, onError) => {
  return dispatch => {
    return api.post(`${process.env.REACT_APP_API_URL}/investor/pass-startup`, data).then(res => {
      if (res.result) {
        dispatch(setPassDealSubmitPage(true))
        onSuccess(res)
        return res
      } else {
        onError(res)
        if (res.error === 'token expired' && res.is_expired) {
          dispatch(fetchInvestorUserLogout())
          dispatch({
            type: 'SET_AUTH_EXPIRED',
          })
        }
        return false
      }
    })
  }
}

export const fetchInvestorDealDetail = id => {
  return dispatch => {
    return api
      .post(`${process.env.REACT_APP_API_URL}/investor/startup-detail`, {
        id,
      })
      .then(res => {
        if (res.result) {
          dispatch(getInvestorDealDetail(res.data))
          return res.data
        } else {
          if (res.error === 'token expired' && res.is_expired) {
            dispatch(fetchInvestorUserLogout())
            dispatch({
              type: 'SET_AUTH_EXPIRED',
            })
          }
          return false
        }
      })
  }
}

export const fetchInvestorDealQuestions = (id, page, type) => {
  return dispatch => {
    dispatch(setInvestorDealQuestionLoader(true))
    return api
      .post(`${process.env.REACT_APP_API_URL}/investor/get-questions?id=${id}`, {
        page_num: page,
        page_size: 5,
        question_type: type,
      })
      .then(res => {
        if (res.result) {
          dispatch(getInvestorDealQuestion(res.data))
          return res
        } else {
          dispatch(setInvestorDealQuestionLoader(false))
          if (res.error === 'token expired' && res.is_expired) {
            dispatch(fetchInvestorUserLogout())
            dispatch({
              type: 'SET_AUTH_EXPIRED',
            })
          }
          return false
        }
      })
  }
}

export const postInvestorDealQuestions = data => {
  return dispatch => {
    return api
      .post(`${process.env.REACT_APP_API_URL}/investor/post-question`, {
        question: data.question,
        startup_id: data.id,
        is_anonymous: data.is_anonymous,
      })
      .then(res => {
        if (res.result) {
          return res
        } else {
          if (res.error === 'token expired' && res.is_expired) {
            dispatch(fetchInvestorUserLogout())
            dispatch({
              type: 'SET_AUTH_EXPIRED',
            })
          }
          return false
        }
      })
  }
}

export const fetchInvestorMonthlyUpdates = id => {
  return dispatch => {
    return api.get(`${process.env.REACT_APP_API_URL}/investor/get-monthly-updates?id=${id}`).then(res => {
      if (res.result) {
        dispatch(getInvestorMonthlyUpdates(res.data))
        return res
      } else {
        if (res.error === 'token expired' && res.is_expired) {
          dispatch(fetchInvestorUserLogout())
          dispatch({
            type: 'SET_AUTH_EXPIRED',
          })
        } else {
          dispatch(getInvestorMonthlyUpdates([]))
        }
        return false
      }
    })
  }
}

export const postMetricDataPlot = (id, data) => {
  return dispatch => {
    return api
      .post(`${process.env.REACT_APP_API_URL}/investor/metric-data-plot?id=${id}`, {
        key_metric: data,
      })
      .then(res => {
        if (res.result) {
          dispatch(getInvestorMonthlyUpdatesMetricData(res.data))
          return res
        } else {
          if (res.error === 'token expired' && res.is_expired) {
            dispatch(fetchInvestorUserLogout())
            dispatch({
              type: 'SET_AUTH_EXPIRED',
            })
          }
          return false
        }
      })
  }
}

export const fetchInvestorPassedDeals = data => {
  return dispatch => {
    dispatch({
      type: actionTypes.MANAGE_BANK_ACCOUNT_INIT,
    })
    return api
      .post(`${process.env.REACT_APP_API_URL}/investor/get-passed`, {
        page_num: data,
        page_size: 10,
      })
      .then(res => {
        if (res.result) {
          dispatch(getInvestorPassedDeals(res.data, res.total, res.karma_points))
          return res
        } else {
          dispatch({
            type: actionTypes.MANAGE_BANK_ACCOUNT_FAILED,
          })
          if (res.error === 'token expired' && res.is_expired) {
            dispatch(fetchInvestorUserLogout())
            dispatch({
              type: 'SET_AUTH_EXPIRED',
            })
          }
          return false
        }
      })
  }
}

export const undoInvestorPassedDeals = data => {
  return dispatch => {
    return api.post(`${process.env.REACT_APP_API_URL}/investor/undo-pass-startup`, data).then(res => {
      if (res.result) {
        return res
      } else {
        if (res.error === 'token expired' && res.is_expired) {
          dispatch(fetchInvestorUserLogout())
          dispatch({
            type: 'SET_AUTH_EXPIRED',
          })
        }
        return false
      }
    })
  }
}

export const changeInvestorPassword = data => {
  return dispatch => {
    return api.post(`${process.env.REACT_APP_API_URL}/investor/change-password`, data).then(res => {
      if (res.result) {
        return res
      } else {
        if (res.error === 'token expired' && res.is_expired) {
          dispatch(fetchInvestorUserLogout())
          dispatch({
            type: 'SET_AUTH_EXPIRED',
          })
        }
        return res
      }
    })
  }
}

export const fetchInvestorEmailNotification = () => {
  return dispatch => {
    return api.get(`${process.env.REACT_APP_API_URL}/investor/email-notification`).then(res => {
      if (res.result) {
        // dispatch(getInvestorPassedDeals(res.data, res.total))
        return res
      } else {
        if (res.error === 'token expired' && res.is_expired) {
          dispatch(fetchInvestorUserLogout())
          dispatch({
            type: 'SET_AUTH_EXPIRED',
          })
        }
        return res
      }
    })
  }
}

export const saveEmailNotification = data => {
  return dispatch => {
    return api.post(`${process.env.REACT_APP_API_URL}/investor/email-notification`, data).then(res => {
      if (res.result) {
        // dispatch(getInvestorPassedDeals(res.data, res.total))
        return res
      } else {
        if (res.error === 'token expired' && res.is_expired) {
          dispatch(fetchInvestorUserLogout())
          dispatch({
            type: 'SET_AUTH_EXPIRED',
          })
        }
        return res
      }
    })
  }
}

export const getNotificationData = data => {
  return dispatch => {
    return api.post(`${process.env.REACT_APP_API_URL}/investor/notifications`, data).then(res => {
      if (res.result) {
        // dispatch(getInvestorPassedDeals(res.data, res.total))
        return res
      } else {
        if (res.error === 'token expired' && res.is_expired) {
          dispatch(fetchInvestorUserLogout())
          dispatch({
            type: 'SET_AUTH_EXPIRED',
          })
        }
        return res
      }
    })
  }
}

export const shareDeal = (data, id) => {
  return dispatch => {
    return api
      .post(`${process.env.REACT_APP_API_URL}/investor/share-deal?startup_id=${id}`, data)
      .then(res => {
        if (res.result) {
          // dispatch(getInvestorPassedDeals(res.data, res.total))
          return res
        } else {
          if (res.error === 'token expired' && res.is_expired) {
            dispatch(fetchInvestorUserLogout())
            dispatch({
              type: 'SET_AUTH_EXPIRED',
            })
          }
          return res
        }
      })
  }
}

export const getNotificationDropdownData = data => {
  return dispatch => {
    return api
      .post(`${process.env.REACT_APP_API_URL}/investor/notification-dropdown`, {
        is_clicked: data,
      })
      .then(res => {
        if (res.result) {
          // dispatch(getInvestorPassedDeals(res.data, res.total))
          return res
        } else {
          if (res.error === 'token expired' && res.is_expired) {
            dispatch(fetchInvestorUserLogout())
            dispatch({
              type: 'SET_AUTH_EXPIRED',
            })
          }
          return res
        }
      })
  }
}

export const clearInvestorNotification = () => {
  return dispatch => {
    return api
      .post(`${process.env.REACT_APP_API_URL}/investor/notification-dropdown`, {
        is_clicked: true,
        clear: true,
      })
      .then(res => {
        if (res.result) {
          return res
        } else {
          if (res.error === 'token expired' && res.is_expired) {
            dispatch(fetchInvestorUserLogout())
            dispatch({
              type: 'SET_AUTH_EXPIRED',
            })
          }
          return res
        }
      })
  }
}

export const fetchInvestorKarmaPoints = () => {
  return dispatch => {
    return api.get(`${process.env.REACT_APP_API_URL}/investor/karma-points`).then(res => {
      if (res.result) {
        dispatch(getInvestorKarmaPoints(res.karma_points))
        return res
      } else {
        if (res.error === 'token expired' && res.is_expired) {
          dispatch(fetchInvestorUserLogout())
          dispatch({
            type: 'SET_AUTH_EXPIRED',
          })
        }
        return res
      }
    })
  }
}

export const getCustomerInfo = () => {
  return async dispatch => {
    dispatch({
      type: actionTypes.GET_CUSTOMER_INFO_INIT,
    })
    await api
      .get(`${process.env.REACT_APP_API_URL}/investor/customer-info`)
      .then(res => {
        dispatch({
          type: actionTypes.GET_CUSTOMER_INFO_SUCCESS,
          payload: res,
        })
        return res
      })
      .catch(error => {
        dispatch({
          type: actionTypes.GET_CUSTOMER_INFO_FAILED,
        })
        throw error?.response?.data?.error || error?.message
      })
  }
}

export const addEmail = (data, onSuccess, onError) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.POST_ANSWER_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/investor/add-email`, data)
      .then(res => {
        if (res.result) {
          dispatch({
            type: actionTypes.POST_ANSWER_SUCCESS,
            payload: res,
          })
          onSuccess(res)
        } else {
          dispatch({
            type: actionTypes.POST_ANSWER_FAILED,
          })
          onError(res.error)
        }
      })
      .catch(error => {
        dispatch({
          type: actionTypes.POST_ANSWER_FAILED,
        })
        onError(error.error)
        throw error?.response?.data?.error || error?.message
      })
  }
}

export const createCustomer = (data, onSuccess, onError) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.CREATE_CUSTOMER_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/investor/create-customer`, data)
      .then(res => {
        if (res?.result) {
          dispatch({
            type: actionTypes.CREATE_CUSTOMER_SUCCESS,
            payload: res,
          })
          onSuccess(res)
        } else {
          dispatch({
            type: actionTypes.CREATE_CUSTOMER_FAILED,
          })
          onError(res.error)
        }
      })
      .catch(error => {
        dispatch({
          type: actionTypes.CREATE_CUSTOMER_FAILED,
        })
        onError(error.error)
        throw error?.response?.data?.error || error?.message
      })
  }
}

export const updateCustomer = (data, onSuccess, onError) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.UPDATE_CUSTOMER_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/investor/update-customer`, data)
      .then(res => {
        if (res.result) {
          dispatch({
            type: actionTypes.UPDATE_CUSTOMER_SUCCESS,
            payload: res,
          })
          onSuccess(res)
        } else {
          dispatch({
            type: actionTypes.UPDATE_CUSTOMER_FAILED,
          })
          onError(res.error)
        }
      })
      .catch(error => {
        dispatch({
          type: actionTypes.UPDATE_CUSTOMER_FAILED,
        })
        onError(error.error)
        throw error?.response?.data?.error || error?.message
      })
  }
}

export const retrieveCustomer = data => {
  return async dispatch => {
    dispatch({
      type: actionTypes.RETRIEVE_CUSTOMER_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/investor/retrieve-customer`, data)
      .then(res => {
        dispatch({
          type: actionTypes.RETRIEVE_CUSTOMER_SUCCESS,
          payload: res,
        })
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.RETRIEVE_CUSTOMER_FAILED,
        })
        throw error?.response?.data?.error || error?.message
      })
  }
}

export const submitDocument = (data, onSuccess, onError) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.SUBMIT_DOC_INIT,
    })
    await api
      .postwithReqForm(`${process.env.REACT_APP_API_URL}/investor/submit-document`, data)
      .then(res => {
        if (res.result) {
          dispatch({
            type: actionTypes.SUBMIT_DOC_SUCCESS,
            payload: res,
          })
          onSuccess(res)
        } else {
          dispatch({
            type: actionTypes.GET_CUSTOMER_INFO_FAILED,
          })
          onError(res.error)
        }
      })
      .catch(error => {
        dispatch({
          type: actionTypes.GET_CUSTOMER_INFO_FAILED,
        })
        onError(error.error)
        throw error?.response?.data?.error || error?.message
      })
  }
}

export const getPreviousInvestmentData = (id, onSuccess) => {
  return async dispatch => {
    await api
      .getwithReqForm(`${process.env.REACT_APP_API_URL}/investor/previous-investment-data?id=${id}`)
      .then(res => {
        if (res.result) {
          onSuccess(res.prev_investment_data)
        }
        return
      })
      .catch(error => {
        throw error?.response?.data?.error || error?.message
      })
  }
}

export const createLinkToken = (data, onSuccess, onError) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.POST_LINK_TOKEN_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/plaid/create_link_token`, data)
      .then(res => {
        dispatch({
          type: actionTypes.POST_LINK_TOKEN_SUCCESS,
          payload: res,
        })
        onSuccess && onSuccess(res)
        // return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.POST_LINK_TOKEN_FAILED,
        })
        onError && onError(error?.response?.data?.error || error?.message)
        throw error?.response?.data?.error || error?.message
      })
  }
}

export const setAccessToken = (data, metadata) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.SET_ACCESS_TOKEN_INIT,
    })
    await api
      .postwithReqForm(`${process.env.REACT_APP_API_URL}/plaid/set_access_token`, data)
      .then(res => {
        dispatch({
          type: actionTypes.SET_ACCESS_TOKEN_SUCCESS,
          payload: res,
        })
        const fundingFormData = new FormData()
        fundingFormData.append('account_id', metadata?.account_id)
        fundingFormData.append('mask', metadata?.account?.mask)
        fundingFormData.append('name', metadata?.account.name)
        fundingFormData.append('bank_name', metadata?.institution.name)
        fundingFormData.append('plaid_access_token', res?.access_token)
        dispatch(addFundingAccount(fundingFormData))
      })
      .catch(error => {
        dispatch({
          type: actionTypes.SET_ACCESS_TOKEN_FAILED,
        })
        throw error
      })
  }
}

export const addFundingAccount = data => {
  return async dispatch => {
    dispatch({
      type: actionTypes.ADD_FUNDING_ACCOUNT_INIT,
    })
    await api
      .postwithReqForm(`${process.env.REACT_APP_API_URL}/investor/add-funding-account`, data)
      .then(res => {
        dispatch({
          type: actionTypes.ADD_FUNDING_ACCOUNT_SUCCESS,
          payload: res,
        })
        dispatch(manageBankAccount())
        if (res.result) {
          return res
        } else {
          throw res.error
        }
      })
      .catch(error => {
        dispatch({
          type: actionTypes.ADD_FUNDING_ACCOUNT_FAILED,
          payload: error,
        })
        throw error
      })
  }
}

export const manageBankAccount = () => {
  return async dispatch => {
    dispatch({
      type: actionTypes.MANAGE_BANK_ACCOUNT_INIT,
    })
    await api
      .get(`${process.env.REACT_APP_API_URL}/investor/manage-bank-accounts`)
      .then(res => {
        dispatch({
          type: actionTypes.MANAGE_BANK_ACCOUNT_SUCCESS,
          payload: res,
        })
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.MANAGE_BANK_ACCOUNT_FAILED,
        })
        throw error?.response?.data?.error || error?.message
      })
  }
}

export const deleteBankAccount = data => {
  return async dispatch => {
    dispatch({
      type: actionTypes.MANAGE_BANK_ACCOUNT_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/investor/manage-bank-accounts`, data)
      .then(res => {
        dispatch({
          type: actionTypes.DELETE_BANK_ACCOUNT_SUCCESS,
          payload: res,
        })
        dispatch(manageBankAccount())
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.DELETE_BANK_ACCOUNT_SUCCESS,
        })
        throw error?.response?.data?.error || error?.message
      })
  }
}

export const getWithdrawMoney = () => {
  return async dispatch => {
    dispatch({
      type: actionTypes.GET_WITHDRAW_MONEY_INIT,
    })
    await api
      .get(`${process.env.REACT_APP_API_URL}/investor/withdraw-money`)
      .then(res => {
        dispatch({
          type: actionTypes.GET_WITHDRAW_MONEY_SUCCESS,
          payload: res,
        })
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.GET_WITHDRAW_MONEY_FAILED,
        })
        throw error?.response?.data?.error || error?.message
      })
  }
}

export const postWithdrawMoney = (data, onSuccess, onError) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.POST_WITHDRAW_MONEY_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/investor/withdraw-money`, data)
      .then(res => {
        dispatch({
          type: actionTypes.POST_WITHDRAW_MONEY_SUCCESS,
          payload: res,
        })
        onSuccess && onSuccess(res)
        // return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.POST_WITHDRAW_MONEY_FAILED,
        })
        onError && onError(error?.response?.data?.error || error?.message)
        throw error?.response?.data?.error || error?.message
      })
  }
}

export const getDepositMoney = (onSuccess, onError) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.GET_DEPOSIT_MONEY_INIT,
    })
    await api
      .get(`${process.env.REACT_APP_API_URL}/investor/deposit-money`)
      .then(res => {
        dispatch({
          type: actionTypes.GET_DEPOSIT_MONEY_SUCCESS,
          payload: res,
        })

        onSuccess && onSuccess(res)
        // return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.GET_DEPOSIT_MONEY_FAILED,
        })
        onError && onError(error?.response?.data?.error || error?.message)
        throw error?.response?.data?.error || error?.message
      })
  }
}

export const postDepositMoney = (data, onSuccess, onError) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.POST_DEPOSIT_MONEY_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/investor/deposit-money`, data)
      .then(res => {
        dispatch({
          type: actionTypes.POST_DEPOSIT_MONEY_SUCCESS,
          payload: res,
        })
        onSuccess && onSuccess(res)
        // return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.POST_DEPOSIT_MONEY_FAILED,
        })
        onError && onError(error?.response?.data?.error || error?.message)
        throw error?.response?.data?.error || error?.message
      })
  }
}

export const getAutoRefill = () => {
  return async dispatch => {
    dispatch({
      type: actionTypes.GET_REFILL_MONEY_INIT,
    })
    await api
      .get(`${process.env.REACT_APP_API_URL}/investor/auto-refill`)
      .then(res => {
        dispatch({
          type: actionTypes.GET_REFILL_MONEY_SUCCESS,
          payload: res,
        })
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.GET_REFILL_MONEY_FAILED,
        })
        throw error?.response?.data?.error || error?.message
      })
  }
}

export const getInvestorProfile = onSuccess => {
  return async dispatch => {
    await api
      .get(`${process.env.REACT_APP_API_URL}/investor/profile`)
      .then(res => {
        dispatch({
          type: SET_PROFILE_DATA,
          payload: res,
        })
        if (res.result && onSuccess) {
          onSuccess(res)
        }
        return res
      })
      .catch(error => {
        throw error?.response?.data?.error || error?.message
      })
  }
}

export const postAutoRefill = data => {
  return async dispatch => {
    dispatch({
      type: actionTypes.POST_REFILL_MONEY_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/investor/auto-refill`, data)
      .then(res => {
        dispatch({
          type: actionTypes.POST_REFILL_MONEY_SUCCESS,
          payload: res,
        })
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.POST_REFILL_MONEY_FAILED,
        })
        throw error?.response?.data?.error || error?.message
      })
  }
}

// export const postInvestingSteps = (data, id) => {
//   return async dispatch => {
//     dispatch({
//       type: actionTypes.POST_INVESTING_STEPS_INIT,
//     })
//     // '62397b6e235acd40068a1b3e'
//     await api
//       .post(`${process.env.REACT_APP_API_URL}/investor/investing-steps?id=${id}`, data)
//       .then(res => {
//         dispatch({
//           type: actionTypes.POST_INVESTING_STEPS_SUCCESS,
//           payload: res,
//         })
//         return res
//       })
//       .catch(error => {
//         dispatch({
//           type: actionTypes.POST_INVESTING_STEPS_FAILED,
//         })
//         throw error
//       })
//   }
// }

export const postInvestingSteps = (data, id) => {
  return dispatch => {
    dispatch({
      type: actionTypes.POST_INVESTING_STEPS_INIT,
    })
    return api
      .post(`${process.env.REACT_APP_API_URL}/investor/investing-steps?id=${id}`, data)
      .then(res => {
        if (res.result) {
          dispatch({
            type: actionTypes.POST_INVESTING_STEPS_SUCCESS,
            payload: res,
          })
          return res
        } else {
          dispatch({
            type: actionTypes.POST_INVESTING_STEPS_FAILED,
            error: res.message,
          })
          if (res.error === 'token expired' && res.is_expired) {
            dispatch(fetchInvestorUserLogout())
            dispatch({
              type: 'SET_AUTH_EXPIRED',
            })
          }
          return res
        }
      })
      .catch(() =>
        dispatch({
          type: actionTypes.POST_INVESTING_STEPS_FAILED,
        }),
      )
  }
}

export const investMoney = (onSuccess, onError) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.GET_INVEST_MONEY_INIT,
    })
    await api
      .get(`${process.env.REACT_APP_API_URL}/investor/invest-money`)
      .then(res => {
        if (res.result) {
          dispatch({
            type: actionTypes.GET_INVEST_MONEY_SUCCESS,
            payload: res,
          })
          if (onSuccess) {
            onSuccess(res)
          }
        } else {
          dispatch({
            type: actionTypes.GET_INVEST_MONEY_FAILED,
          })
          onError(res.error)
          throw res.error
        }
      })
      .catch(error => {
        dispatch({
          type: actionTypes.GET_INVEST_MONEY_FAILED,
        })
        onError(error.error)
        throw error
      })
  }
}

export const fetchInvestmentHistory = (data, onSuccess) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.GET_INVESTMENT_HISTORY_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/investor/investment-history`, data)
      .then(res => {
        dispatch({
          type: actionTypes.GET_INVESTMENT_HISTORY_SUCCESS,
          payload: res.data,
          totalCount: res.total,
          bankAccounts: res.bank_accounts,
          min_invested: res.min_invested,
          max_invested: res.max_invested,
        })
        onSuccess && onSuccess(res)
      })
      .catch(error => {
        dispatch({
          type: actionTypes.GET_INVESTMENT_HISTORY_FAILED,
        })
        throw error
      })
  }
}

export const portalRedirection = (url = 'investor', data) => {
  return dispatch => {
    return api
      .post(`${process.env.REACT_APP_API_URL}/${url}/portal-redirection`, {
        portal: data,
      })
      .then(res => {
        if (res.result) {
          // dispatch(getInvestorPassedDeals(res.data, res.total))
          return res
        } else {
          if (res.error === 'token expired' && res.is_expired) {
            dispatch(fetchInvestorUserLogout())
            dispatch({
              type: 'SET_AUTH_EXPIRED',
            })
          }
          return res
        }
      })
  }
}

export const updateInvestingType = (data, onSuccess, onError) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.UPDATE_INVESTING_TYPE_INIT,
    })
    await api
      .patch(`${process.env.REACT_APP_API_URL}/investor/update-info`, data)
      .then(res => {
        if (res.result) {
          dispatch({
            type: actionTypes.UPDATE_INVESTING_TYPE_SUCCESS,
            payload: res,
          })
          onSuccess && onSuccess(res)
          return res
        } else {
          dispatch({
            type: actionTypes.UPDATE_INVESTING_TYPE_FAILED,
          })
          onError(res.error)
        }
      })
      .catch(error => {
        dispatch({
          type: actionTypes.UPDATE_INVESTING_TYPE_FAILED,
        })
        throw error?.response?.data?.error || error?.message
      })
  }
}

export const updateBusinessType = (data, onSuccess, onError) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.UPDATE_BUSINESS_TYPE_INIT,
    })
    await api
      .patch(`${process.env.REACT_APP_API_URL}/investor/update-info`, data)
      .then(res => {
        if (res.result) {
          dispatch({
            type: actionTypes.UPDATE_BUSINESS_TYPE_SUCCESS,
            payload: res,
          })
          onSuccess && onSuccess(res)
          return res
        } else {
          dispatch({
            type: actionTypes.UPDATE_BUSINESS_TYPE_FAILED,
          })
          onError(res.error)
        }
      })
      .catch(error => {
        dispatch({
          type: actionTypes.UPDATE_BUSINESS_TYPE_FAILED,
        })
        throw error?.response?.data?.error || error?.message
      })
  }
}

// Action to send verify email
export const sendVerifyEmail = (data, onSuccess, onError) => {
  return async dispatch => {
    await api
      .post(`${process.env.REACT_APP_API_URL}/investor/add-email`, data)
      .then(res => {
        if (res.result) {
          onSuccess(res)
        } else {
          onError(res.error)
        }
      })
      .catch(error => {
        onError(error.error)
        throw error?.response?.data?.error || error?.message
      })
  }
}

// Action to check email response
export const checkEmailResponse = (payload, onSuccess, onError) => {
  return async () => {
    await api
      .get(`${process.env.REACT_APP_API_URL}/investor/check-dwolla-email-verification-status`)
      .then(res => {
        if (res.result) {
          onSuccess(res)
        } else {
          onError(res.error)
        }
      })
      .catch(error => {
        onError(error.error)
        throw error?.response?.data?.error || error?.message
      })
  }
}

// Action to get Industry classification categories
export const getIndustryClassificationCategory = () => {
  return async dispatch => {
    dispatch({
      type: actionTypes.GET_INDUSTRY_CLASSIFICATIONS_INIT,
    })
    await api
      .get(`${process.env.REACT_APP_API_URL}/investor/industry-classifications`)
      .then(res => {
        dispatch({
          type: actionTypes.GET_INDUSTRY_CLASSIFICATIONS_SUCCESS,
          payload: res?.classifications,
        })
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.GET_INDUSTRY_CLASSIFICATIONS_FAILED,
        })
        throw error?.response?.data?.error
      })
  }
}

// Action to get business classification categories
export const getBusinessClassificationCategory = data => {
  return async dispatch => {
    dispatch({
      type: actionTypes.GET_BUSINESS_CLASSIFICATIONS_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/investor/business-classifications`, data)
      .then(res => {
        dispatch({
          type: actionTypes.GET_BUSINESS_CLASSIFICATIONS_SUCCESS,
          payload: res?.industry_classifications,
        })
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.GET_BUSINESS_CLASSIFICATIONS_FAILED,
        })
        throw error?.response?.data?.error
      })
  }
}

// create customer
export const createDwollaCustomer = (data, onSuccess, onError) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.CREATE_DWOLLA_CUSTOMER_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/investor/create-customer`, data)
      .then(res => {
        if (res.result) {
          dispatch({
            type: actionTypes.CREATE_DWOLLA_CUSTOMER_SUCCESS,
            payload: res,
          })
          onSuccess(res)
        } else {
          dispatch({
            type: actionTypes.CREATE_DWOLLA_CUSTOMER_FAILED,
          })
          onError(res.error)
        }
      })
      .catch(error => {
        dispatch({
          type: actionTypes.CREATE_DWOLLA_CUSTOMER_FAILED,
        })
        onError(error.error)
        throw error?.response?.data?.error || error?.message
      })
  }
}

// update customer
export const updateDwollaCustomer = (data, onSuccess, onError) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.UPDATE_DWOLLA_CUSTOMER_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/investor/update-customer`, data)
      .then(res => {
        if (res.result) {
          dispatch({
            type: actionTypes.UPDATE_DWOLLA_CUSTOMER_SUCCESS,
            payload: res,
          })
          onSuccess(res)
        } else {
          dispatch({
            type: actionTypes.UPDATE_DWOLLA_CUSTOMER_FAILED,
          })
          onError(res.error)
        }
      })
      .catch(error => {
        dispatch({
          type: actionTypes.UPDATE_DWOLLA_CUSTOMER_FAILED,
        })
        onError(error.error)
        throw error?.response?.data?.error || error?.message
      })
  }
}

export const certifyUser = (data, onSuccess, onError) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.CERTIFY_USER_INIT,
    })
    await api
      .get(`${process.env.REACT_APP_API_URL}/investor/certify-user`, undefined)
      .then(res => {
        if (res.result) {
          dispatch({
            type: actionTypes.CERTIFY_USER_SUCCESS,
            payload: res,
          })
          onSuccess(res)
        } else {
          dispatch({
            type: actionTypes.CERTIFY_USER_FAILED,
          })
          onError(res.error)
        }
      })
      .catch(error => {
        dispatch({
          type: actionTypes.CERTIFY_USER_FAILED,
        })
        onError(error.error)
        throw error?.response?.data?.error || error?.message
      })
  }
}

export const updateBeneficialOwnerExists = (data, onSuccess, onError) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.UPDATE_BENEFICIAL_OWNER_EXISTS_INIT,
    })
    await api
      .patch(`${process.env.REACT_APP_API_URL}/investor/update-info`, data)
      .then(res => {
        if (res.result) {
          dispatch({
            type: actionTypes.UPDATE_BENEFICIAL_OWNER_EXISTS_SUCCESS,
            payload: res,
          })
          onSuccess && onSuccess(res)
          return res
        } else {
          dispatch({
            type: actionTypes.UPDATE_BENEFICIAL_OWNER_EXISTS_FAILED,
          })
          onError(res.error)
        }
      })
      .catch(error => {
        dispatch({
          type: actionTypes.UPDATE_BENEFICIAL_OWNER_EXISTS_FAILED,
        })
        throw error?.response?.data?.error || error?.message
      })
  }
}

export const getBeneficialOwnersList = () => {
  return async dispatch => {
    dispatch({
      type: actionTypes.GET_BENEFICIAL_OWNER_INIT,
    })
    await api
      .get(`${process.env.REACT_APP_API_URL}/investor/list-beneficial-owners`)
      .then(res => {
        dispatch({
          type: actionTypes.GET_BENEFICIAL_OWNER_SUCCESS,
          payload: res.data,
        })
        return res
      })
      .catch(error => {
        dispatch({
          type: actionTypes.GET_BENEFICIAL_OWNER_FAILED,
        })
        throw error?.response?.data?.error || error?.message
      })
  }
}

// Add Beneficial Owner
export const addBeneficialOwner = (data, onSuccess, onError) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.ADD_BENEFICIAL_OWNER_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/investor/add-beneficial-owner`, data)
      .then(res => {
        if (res.result) {
          dispatch({
            type: actionTypes.ADD_BENEFICIAL_OWNER_SUCCESS,
            payload: res,
          })
          onSuccess(res)
        } else {
          dispatch({
            type: actionTypes.ADD_BENEFICIAL_OWNER_FAILED,
          })
          onError(res.error)
        }
      })
      .catch(error => {
        dispatch({
          type: actionTypes.ADD_BENEFICIAL_OWNER_FAILED,
        })
        onError(error.error)
        throw error?.response?.data?.error || error?.message
      })
  }
}

// Update Beneficial Owner
export const updateBeneficialOwner = (data, onSuccess, onError) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.UPDATE_BENEFICIAL_OWNER_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/investor/update-beneficial-owner`, data)
      .then(res => {
        if (res.result) {
          dispatch({
            type: actionTypes.UPDATE_BENEFICIAL_OWNER_SUCCESS,
            payload: res,
          })
          onSuccess(res)
        } else {
          dispatch({
            type: actionTypes.UPDATE_BENEFICIAL_OWNER_FAILED,
          })
          onError(res.error)
        }
      })
      .catch(error => {
        dispatch({
          type: actionTypes.UPDATE_BENEFICIAL_OWNER_FAILED,
        })
        onError(error.error)
        throw error?.response?.data?.error || error?.message
      })
  }
}

export const removeBeneficialOwner = (data, onSuccess, onError) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.REMOVE_BENEFICIAL_OWNER_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/investor/remove-beneficial-owner`, data)
      .then(res => {
        if (res.result) {
          dispatch({
            type: actionTypes.REMOVE_BENEFICIAL_OWNER_SUCCESS,
            payload: res,
          })
          onSuccess && onSuccess(res)
          return res
        } else {
          dispatch({
            type: actionTypes.REMOVE_BENEFICIAL_OWNER_FAILED,
          })
          onError(res.error)
        }
      })
      .catch(error => {
        dispatch({
          type: actionTypes.REMOVE_BENEFICIAL_OWNER_FAILED,
        })
        throw error?.response?.data?.error || error?.message
      })
  }
}

export const beneficialOwnerDocumentVerify = (data, onSuccess, onError) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.SUBMIT_BENEFICIAL_DOC_INIT,
    })
    await api
      .postwithReqForm(`${process.env.REACT_APP_API_URL}/investor/verify-document-beneficial-owner`, data)
      .then(res => {
        if (res.result) {
          dispatch({
            type: actionTypes.SUBMIT_BENEFICIAL_DOC_SUCCESS,
            payload: res,
          })
          onSuccess && onSuccess(res)
          return res
        } else {
          dispatch({
            type: actionTypes.SUBMIT_BENEFICIAL_DOC_FAILED,
          })
          onError(res.error)
        }
      })
      .catch(error => {
        dispatch({
          type: actionTypes.SUBMIT_BENEFICIAL_DOC_FAILED,
        })
        throw error?.response?.data?.error || error?.message
      })
  }
}

export const addPostInvestingStepsData = data => {
  const key = Object.keys(data)[0]
  const value = Object.values(data)[0]
  return {
    type: ADD_INVESTING_STEPS_DATA,
    payload: {
      step: key,
      data: value,
    },
  }
}

export const addAllPostInvestingStepsData = data => {
  return {
    type: ADD_ALL_INVESTING_STEPS_DATA,
    payload: data,
  }
}

export const removePostInvestingStepsData = () => {
  return {
    type: REMOVE_INVESTING_STEPS_DATA,
  }
}

export const setAccessToken_NEW = (data, metadata, onSuccess) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.SET_ACCESS_TOKEN_INIT,
    })
    await api
      .postwithReqForm(`${process.env.REACT_APP_API_URL}/plaid/set_access_token`, data)
      .then(res => {
        dispatch({
          type: actionTypes.SET_ACCESS_TOKEN_SUCCESS,
          payload: res,
        })
        const fundingFormData = new FormData()
        fundingFormData.append('account_id', metadata?.account_id)
        fundingFormData.append('mask', metadata?.account?.mask)
        fundingFormData.append('name', metadata?.account.name)
        fundingFormData.append('bank_name', metadata?.institution.name)
        fundingFormData.append('plaid_access_token', res?.access_token)
        // dispatch(addFundingAccount(fundingFormData))
        onSuccess && onSuccess(fundingFormData)
      })
      .catch(error => {
        dispatch({
          type: actionTypes.SET_ACCESS_TOKEN_FAILED,
        })
        throw error
      })
  }
}

export const addFundingAccount_NEW = (data, onSuccess) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.ADD_FUNDING_ACCOUNT_INIT,
    })
    await api
      .postwithReqForm(`${process.env.REACT_APP_API_URL}/investor/add-funding-account`, data)
      .then(res => {
        dispatch({
          type: actionTypes.ADD_FUNDING_ACCOUNT_SUCCESS,
          payload: res,
        })
        onSuccess && onSuccess(res)
        // dispatch(manageBankAccount())
        if (res.result) {
          return res
        } else {
          throw res.error
        }
      })
      .catch(error => {
        dispatch({
          type: actionTypes.ADD_FUNDING_ACCOUNT_FAILED,
          payload: error,
        })
        throw error
      })
  }
}

export const manageBankAccount_NEW = (onSuccess, onError) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.MANAGE_BANK_ACCOUNT_INIT,
    })
    await api
      .get(`${process.env.REACT_APP_API_URL}/investor/manage-bank-accounts`)
      .then(res => {
        dispatch({
          type: actionTypes.MANAGE_BANK_ACCOUNT_SUCCESS,
          payload: res,
        })
        onSuccess && onSuccess(res)
        // return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.MANAGE_BANK_ACCOUNT_FAILED,
        })
        onError && onError(error?.response?.data?.error || error?.message)
        throw error?.response?.data?.error || error?.message
      })
  }
}

export const getCustomerInfo_NEW = (onSuccess, onError) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.GET_CUSTOMER_INFO_INIT,
    })
    await api
      .get(`${process.env.REACT_APP_API_URL}/investor/customer-info`)
      .then(res => {
        dispatch({
          type: actionTypes.GET_CUSTOMER_INFO_SUCCESS,
          payload: res,
        })
        onSuccess && onSuccess(res)
        // return res
      })
      .catch(error => {
        dispatch({
          type: actionTypes.GET_CUSTOMER_INFO_FAILED,
        })
        onError && onError(error?.response?.data?.error || error?.message)
        throw error?.response?.data?.error || error?.message
      })
  }
}

export const fetchInvestorDealDetailNew = (id, onSuccess, onError) => {
  return dispatch => {
    return api
      .post(`${process.env.REACT_APP_API_URL}/investor/startup-detail`, {
        id,
      })
      .then(res => {
        if (res.result) {
          dispatch(getInvestorDealDetail(res.data))
          // return res.data
        } else {
          if (res.error === 'token expired' && res.is_expired) {
            dispatch(fetchInvestorUserLogout())
            dispatch({
              type: 'SET_AUTH_EXPIRED',
            })
          }
          // return false
        }
        onSuccess && onSuccess(res)
      })
      .catch(error => {
        onError && onError(error?.response?.data?.error || error?.message)
        throw error?.response?.data?.error || error?.message
      })
  }
}

// Action to get business classification categories
export const getTaxInformation = (data, onSuccess, onError) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.GET_TAX_INFO_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/investor/get-tax-info`, data)
      .then(res => {
        dispatch({
          type: actionTypes.GET_TAX_INFO_SUCCESS,
          payload: res,
        })
        onSuccess && onSuccess(res)
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.GET_TAX_INFO_FAILED,
        })
        onError && onError(error?.response?.data?.error || error?.message)
        throw error?.response?.data?.error
      })
  }
}

// Action to Submit Tax Info
export const submitTaxInformation = (data, onSuccess, onError) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.SUBMIT_TAX_INFO_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/investor/submit-tax-info`, data)
      .then(res => {
        dispatch({
          type: actionTypes.SUBMIT_TAX_INFO_SUCCESS,
          payload: res?.tax_info,
        })
        onSuccess && onSuccess(res)
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.SUBMIT_TAX_INFO_FAILED,
        })
        onError && onError(error?.response?.data?.error || error?.message)
        throw error?.response?.data?.error
      })
  }
}

export const getPersonaInfo = (onSuccess, onError) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.GET_PERSONA_INFO_INIT,
    })
    await api
      .get(`${process.env.REACT_APP_API_URL}/investor/persona-kyc-kyb-info`)
      .then(res => {
        dispatch({
          type: actionTypes.GET_PERSONA_INFO_SUCCESS,
          payload: res,
        })
        onSuccess && onSuccess(res)
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.GET_PERSONA_INFO_FAILED,
        })
        onError && onError(error?.response?.data?.error || error?.message)
        throw error?.response?.data?.error || error?.message
      })
  }
}

export const handleShowTaxFlowSelectionScreen = () => {
  return { type: 'RESET_TAX_USER_TYPE' }
}

export const taxFormFileUpload = (data, onSuccess, onFail) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.TAX_FORM_FILE_UPLOAD_INIT,
    })
    await api
      .postwithFormData(`${process.env.REACT_APP_API_URL}/investor/tax-info-form-links-generator`, data)
      .then(res => {
        // if (!res?.result) {
        //   IsTokenExpired(res, dispatch)
        // }
        if (res?.result) {
          dispatch({
            type: actionTypes.TAX_FORM_FILE_UPLOAD_SUCCESS,
            payload: res,
          })
          onSuccess && onSuccess(res)
          return
        } else {
          dispatch({
            type: actionTypes.TAX_FORM_FILE_UPLOAD_FAILED,
          })
          onFail && onFail(res?.error)
        }
      })
      .catch(error => {
        dispatch({
          type: actionTypes.TAX_FORM_FILE_UPLOAD_FAILED,
        })
        onFail && onFail(error?.response?.data?.error || error?.message)
        throw error?.response?.data?.error || error?.message
      })
  }
}

export const getAccreditationInfo = (onSuccess, onError) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.GET_ACCREDITATION_INFO_INIT,
    })
    await api
      .get(`${process.env.REACT_APP_API_URL}/investor/accreditation-info`)
      .then(res => {
        dispatch({
          type: actionTypes.GET_ACCREDITATION_INFO_SUCCESS,
          payload: res,
        })
        onSuccess && onSuccess(res)
        return res
      })
      .catch(error => {
        dispatch({
          type: actionTypes.GET_ACCREDITATION_INFO_FAILED,
        })
        onError && onError(error?.response?.data?.error || error?.message)
        throw error?.response?.data?.error || error?.message
      })
  }
}

// Action to Submit Accreditation Info
export const submitAccreditationInfo = (data, onSuccess, onError) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.SUBMIT_ACCREDITATION_INFO_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/investor/accreditation-info`, data)
      .then(res => {
        console.log('response: ', res)
        dispatch({
          type: actionTypes.SUBMIT_ACCREDITATION_INFO_SUCCESS,
          payload: res,
        })
        onSuccess && onSuccess(res)
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.SUBMIT_ACCREDITATION_INFO_FAILED,
        })
        onError && onError(error?.response?.data?.error || error?.message)
        throw error?.response?.data?.error
      })
  }
}
