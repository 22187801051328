import * as actionTypes from './ActionTypes'
import api from '../../utils/api'
import { IsTokenExpired } from '../auth/authActions'

// Action to get emailnotificatins data
export const getEmailNotificationsData = () => {
  return async dispatch => {
    dispatch({
      type: actionTypes.GET_EMAIL_NOTIFICATION_INIT,
    })
    await api
      .get(`${process.env.REACT_APP_API_URL}/startup/email-notification`)
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
        }
        dispatch({
          type: actionTypes.GET_EMAIL_NOTIFICATION_SUCCESS,
          payload: res.data,
        })
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.GET_EMAIL_NOTIFICATION_FAILED,
        })
        throw error?.response?.data?.error || error?.message
      })
  }
}

// Action to set email notifications
export const setEmailNotifications = (data, onSuccess, onFail) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.SET_EMAIL_NOTIFICATION_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/startup/email-notification`, data)
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
        }
        dispatch({
          type: actionTypes.SET_EMAIL_NOTIFICATION_SUCCESS,
          payload: res.data,
        })
        onSuccess && onSuccess(res)
        // return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.SET_EMAIL_NOTIFICATION_FAILED,
        })
        onFail && onFail(error?.response?.data?.error || error?.message)
        throw error?.response?.data?.error || error?.message
      })
  }
}

// Action to update a profile data
export const updateProfileData = data => {
  return async dispatch => {
    dispatch({
      type: actionTypes.GET_UPDATE_PROFILE_DATA_INIT,
    })
    await api
      .patch(`${process.env.REACT_APP_API_URL}/startup/update-info`, data)
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
        }
        dispatch({
          type: actionTypes.GET_UPDATE_PROFILE_DATA_SUCCESS,
          payload: res,
        })
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.GET_UPDATE_PROFILE_DATA_FAILED,
        })
        throw error?.response?.data?.error
      })
  }
}

// Action to get questions
export const getQuestion = (data, onSuccess, onFail) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.GET_QUESTION_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/startup/get-questions`, data)
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
        }
        if (res?.result) {
          dispatch({
            type: actionTypes.GET_QUESTION_SUCCESS,
            payload: res,
          })
          onSuccess && onSuccess()
          return
        } else {
          dispatch({
            type: actionTypes.GET_QUESTION_FAILED,
          })
          onFail && onFail()
        }
      })
      .catch(error => {
        dispatch({
          type: actionTypes.GET_QUESTION_FAILED,
        })
        onFail && onFail()
        throw error?.response?.data?.error || error?.message
      })
  }
}

// Action to post answers
export const postAnswer = (data, onSuccess, onFail) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.POST_ANSWER_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/startup/post-answer`, data)
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
        }
        dispatch({
          type: actionTypes.POST_ANSWER_SUCCESS,
          payload: res.data,
        })
        onSuccess && onSuccess()
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.POST_ANSWER_FAILED,
        })
        onFail && onFail()
        throw error?.response?.data?.error || error?.message
      })
  }
}

// Action to fetch status
export const fetchStatus = onSuccess => {
  return async dispatch => {
    dispatch({
      type: actionTypes.FETCH_STATUS_INIT,
    })
    await api
      .get(`${process.env.REACT_APP_API_URL}/startup/status`)
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
        }
        dispatch({
          type: actionTypes.FETCH_STATUS_SUCCESS,
          payload: res,
        })
        onSuccess && onSuccess()
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.FETCH_STATUS_FAILED,
        })
        throw error?.response?.data?.error || error?.message
      })
  }
}

// Action to post allocations status
export const postAllocationStatus = (data, onSuccess, onFail) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.POST_STATUS_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/startup/status`, data)
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
        }
        dispatch({
          type: actionTypes.POST_STATUS_SUCCESS,
          payload: res.data,
        })
        onSuccess && onSuccess(res)
        // return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.POST_STATUS_FAILED,
        })
        onFail && onFail()
        throw error?.response?.data?.error || error?.message
      })
  }
}

// Action to get investors feedback
export const getInvestorFeedback = data => {
  return async dispatch => {
    dispatch({
      type: actionTypes.GET_INVESTOR_FEEDBACK_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/startup/investor-feedback`, data)
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
        }
        dispatch({
          type: actionTypes.GET_INVESTOR_FEEDBACK_SUCCESS,
          payload: res.data,
        })
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.GET_INVESTOR_FEEDBACK_FAILED,
        })
        throw error?.response?.data?.error || error?.message
      })
  }
}

// Action to get notification in dropdown
export const getNotificationDropdown = data => {
  return async dispatch => {
    dispatch({
      type: actionTypes.NOTIFICATION_DROPDOWN_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/startup/notification-dropdown`, data)
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
        }
        dispatch({
          type: actionTypes.NOTIFICATION_DROPDOWN_SUCCESS,
          payload: res,
        })
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.NOTIFICATION_DROPDOWN_FAILED,
        })
        throw error?.response?.data?.error || error?.message
      })
  }
}

// Action to fetch unread notifications count
export const fetchUnreadNotificationCount = () => {
  return async dispatch => {
    dispatch({
      type: actionTypes.GET_UNREAD_NOTIFICATION_COUNT_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/startup/notification-dropdown`, { is_clicked: false })
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
        }
        dispatch({
          type: actionTypes.GET_UNREAD_NOTIFICATION_COUNT_SUCCESS,
          payload: res,
        })
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.GET_UNREAD_NOTIFICATION_COUNT_FAILED,
        })
        throw error?.response?.data?.error || error?.message
      })
  }
}

// Action to fetch unread notifications
export const fetchUnreadNotifications = () => {
  return async dispatch => {
    dispatch({
      type: actionTypes.GET_UNREAD_NOTIFICATION_DATA_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/startup/notification-dropdown`, { is_clicked: true })
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
        }
        dispatch({
          type: actionTypes.GET_UNREAD_NOTIFICATION_DATA_SUCCESS,
          payload: res,
        })
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.GET_UNREAD_NOTIFICATION_DATA_FAILED,
        })
        throw error?.response?.data?.error || error?.message
      })
  }
}

// Action to clear all notifications
export const clearAllNotification = () => {
  return async dispatch => {
    dispatch({
      type: actionTypes.CLEAR_NOTIFICATION_DATA_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/startup/notification-dropdown`, {
        is_clicked: true,
        clear: true,
      })
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
        }
        dispatch({
          type: actionTypes.CLEAR_NOTIFICATION_DATA_SUCCESS,
          payload: res,
        })
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.CLEAR_NOTIFICATION_DATA_FAILED,
        })
        throw error?.response?.data?.error || error?.message
      })
  }
}

// Action to get all notifications data
export const getNotificationData = data => {
  return async dispatch => {
    dispatch({
      type: actionTypes.GET_NOTIFICATION_DATA_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/startup/notifications`, data)
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
        }
        dispatch({
          type: actionTypes.GET_NOTIFICATION_DATA_SUCCESS,
          payload: res,
        })
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.GET_NOTIFICATION_DATA_FAILED,
        })
        throw error?.response?.data?.error || error?.message
      })
  }
}

// Action to active public link
export const publicLinkActivation = (data, onSuccess, onFail) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.PUBLIC_LINK_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/startup/public-link`, data)
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
          onSuccess && onSuccess(res)
          return
        } else {
          dispatch({
            type: actionTypes.PUBLIC_LINK_SUCCESS,
            payload: res.data,
          })
          onSuccess && onSuccess(res)
          return
        }
      })
      .catch(error => {
        dispatch({
          type: actionTypes.PUBLIC_LINK_FAILED,
        })
        onFail && onFail()
        throw error?.response?.data?.error || error?.message
      })
  }
}

// Action to get startup details
export const getStartupDetails = (data, onSuccess) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.STARTUP_DETAIL_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/startup/startup-detail`, data)
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
        }
        dispatch({
          type: actionTypes.STARTUP_DETAIL_SUCCESS,
          payload: res.data,
        })
        onSuccess && onSuccess()
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.STARTUP_DETAIL_FAILED,
        })
        throw error?.response?.data?.error || error?.message
      })
  }
}

// Action to create link token
export const createLinkToken = (data, onSuccess, onError) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.POST_LINK_TOKEN_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/plaid/create_link_token`, data)
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
        }
        dispatch({
          type: actionTypes.POST_LINK_TOKEN_SUCCESS,
          payload: res,
        })
        onSuccess && onSuccess(res)
        // return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.POST_LINK_TOKEN_FAILED,
        })
        onError && onError(error?.response?.data?.error || error?.message)
        throw error?.response?.data?.error || error?.message
      })
  }
}

// Action to set access token
export const setAccessToken = (data, metadata, onSuccess, onFail) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.SET_ACCESS_TOKEN_INIT,
    })
    await api
      .postwithReqForm(`${process.env.REACT_APP_API_URL}/plaid/set_access_token`, data)
      .then(res => {
        dispatch({
          type: actionTypes.SET_ACCESS_TOKEN_SUCCESS,
          payload: res,
        })
        const fundingFormData = new FormData()
        fundingFormData.append('account_id', metadata?.account_id)
        fundingFormData.append('mask', metadata?.account?.mask)
        fundingFormData.append('name', metadata?.account.name)
        fundingFormData.append('bank_name', metadata?.institution.name)
        fundingFormData.append('plaid_access_token', res?.access_token)
        dispatch(addFundingAccount(fundingFormData, onSuccess, onFail))
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.SET_ACCESS_TOKEN_FAILED,
        })
        onFail && onFail()
        throw error?.response?.data?.error || error?.message
      })
  }
}

// Action to fetch completed tour data
export const getCompletedTour = () => {
  return async dispatch => {
    dispatch({
      type: actionTypes.GET_COMPLETED_TOUR_INIT,
    })
    await api
      .get(`${process.env.REACT_APP_API_URL}/startup/tour-completed`)
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
        }
        dispatch({
          type: actionTypes.GET_COMPLETED_TOUR_SUCCESS,
          payload: res,
        })
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.GET_COMPLETED_TOUR_FAILED,
        })
        throw error?.response?.data?.error || error?.message
      })
  }
}

// Action to get customer informations
export const getCustomerInfo = (onSuccess, onError) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.GET_CUSTOMER_INFO_INIT,
    })
    await api
      .get(`${process.env.REACT_APP_API_URL}/startup/customer-info`)
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
        }
        dispatch({
          type: actionTypes.GET_CUSTOMER_INFO_SUCCESS,
          payload: res,
        })
        onSuccess && onSuccess(res)
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.GET_CUSTOMER_INFO_FAILED,
        })
        onError && onError(error)
        throw error?.response?.data?.error || error?.message
      })
  }
}

// Action to add email for SVP formations
export const addEmailForSVP = (payload, onSuccess, onError) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.ADD_EMAIL_SVP_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/startup/add-email`, payload)
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
        }
        if (res?.error) {
          dispatch({
            type: actionTypes.ADD_EMAIL_SVP_FAILED,
          })
          onError && onError(res?.error)
        } else {
          dispatch({
            type: actionTypes.ADD_EMAIL_SVP_SUCCESS,
            payload: res,
          })
          onSuccess && onSuccess(res?.message)
          return
        }
      })
      .catch(error => {
        dispatch({
          type: actionTypes.ADD_EMAIL_SVP_FAILED,
        })
        onError && onError()
        throw error?.response?.data?.error || error?.message
      })
  }
}

// Action to check dwolla email status
export const checkDwollaEmailStatus = async () => {
  try {
    const data = await api.get(`${process.env.REACT_APP_API_URL}/startup/check-dwolla-email-status`)
    return data
  } catch (error) {
    return error?.response?.data
  }
}

// Action to create dwolla customers
export const CreateDwollaCustomer = (payload, onSuccess, onError) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.CREATE_CUSTOMER_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/startup/create-customer`, payload)
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
        }
        dispatch({
          type: actionTypes.CREATE_CUSTOMER_SUCCESS,
          payload: res,
        })
        onSuccess && onSuccess(res)
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.CREATE_CUSTOMER_FAILED,
        })
        onError && onError(error?.response?.data?.error || error?.message)
        throw error?.response?.data?.error || error?.message
      })
  }
}

// Action to add email
export const addEmail = data => {
  return async dispatch => {
    dispatch({
      type: actionTypes.POST_ANSWER_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/startup/add-email`, data)
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
        }
        dispatch({
          type: actionTypes.POST_ANSWER_SUCCESS,
          payload: res,
        })
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.POST_ANSWER_FAILED,
        })
        throw error?.response?.data?.error || error?.message
      })
  }
}

// Action to create customers
export const createCustomer = data => {
  return async dispatch => {
    dispatch({
      type: actionTypes.CREATE_CUSTOMER_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/startup/create-customer`, data)
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
        }
        dispatch({
          type: actionTypes.CREATE_CUSTOMER_SUCCESS,
          payload: res,
        })
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.CREATE_CUSTOMER_FAILED,
        })
        throw error?.response?.data?.error || error?.message
      })
  }
}

// Action to update customers
export const updateCustomer = (data, onSuccess, onFail) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.UPDATE_CUSTOMER_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/startup/update-customer`, data)
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
        }
        dispatch({
          type: actionTypes.UPDATE_CUSTOMER_SUCCESS,
          payload: res,
        })
        onSuccess && onSuccess(res)
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.UPDATE_CUSTOMER_FAILED,
        })
        onFail && onFail(error)
        throw error?.response?.data?.error || error?.message
      })
  }
}

// Action to submit doctuments
export const submitDocument = data => {
  return async dispatch => {
    dispatch({
      type: actionTypes.SUBMIT_DOC_INIT,
    })
    await api
      .postwithReqForm(`${process.env.REACT_APP_API_URL}/startup/submit-document`, data)
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
        }
        dispatch({
          type: actionTypes.SUBMIT_DOC_SUCCESS,
          payload: res,
        })
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.SUBMIT_DOC_FAILED,
        })
        throw error?.response?.data?.error || error?.message
      })
  }
}

// Action to add funding accounts
export const addFundingAccount = (data, onSuccess, onFail) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.ADD_FUNDING_ACCOUNT_INIT,
    })
    await api
      .postwithReqForm(`${process.env.REACT_APP_API_URL}/startup/add-funding-account`, data)
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
        }
        if (res?.result) {
          dispatch({
            type: actionTypes.ADD_FUNDING_ACCOUNT_SUCCESS,
            payload: res,
          })
          onSuccess && onSuccess(res)
          dispatch(manageBankAccount(onSuccess, onFail))
        } else {
          onFail && onFail(res?.error)
        }
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.ADD_FUNDING_ACCOUNT_FAILED,
        })
        onFail && onFail(error?.response?.data?.error || error?.message)
        throw error?.response?.data?.error || error?.message
      })
  }
}

// Action to manage bank accounts
export const manageBankAccount = (onSuccess, onError) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.MANAGE_BANK_ACCOUNT_INIT,
    })
    await api
      .get(`${process.env.REACT_APP_API_URL}/startup/manage-bank-accounts`)
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
        }
        if (res?.result) {
          dispatch({
            type: actionTypes.MANAGE_BANK_ACCOUNT_SUCCESS,
            payload: res,
          })
          onSuccess && onSuccess(res)
          return
        } else {
          dispatch({
            type: actionTypes.MANAGE_BANK_ACCOUNT_FAILED,
          })
          onError && onError(res?.error)
        }
      })
      .catch(error => {
        dispatch({
          type: actionTypes.MANAGE_BANK_ACCOUNT_FAILED,
        })
        onError && onError(error?.response?.data?.error || error?.message)
        throw error?.response?.data?.error || error?.message
      })
  }
}

// Action to delete bank accounts
export const deleteBankAccount = (data, onSuccess, onFail) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.DELETE_BANK_ACCOUNT_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/startup/manage-bank-accounts`, data)
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
        }
        dispatch({
          type: actionTypes.DELETE_BANK_ACCOUNT_SUCCESS,
          payload: res,
        })
        dispatch(manageBankAccount(onSuccess, onFail))
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.DELETE_BANK_ACCOUNT_FAILED,
        })
        throw error?.response?.data?.error || error?.message
      })
  }
}

// Action to get withdraw money
export const getWithdrawMoney = () => {
  return async dispatch => {
    dispatch({
      type: actionTypes.GET_WITHDRAW_MONEY_INIT,
    })
    await api
      .get(`${process.env.REACT_APP_API_URL}/startup/withdraw-money`)
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
        }
        dispatch({
          type: actionTypes.GET_WITHDRAW_MONEY_SUCCESS,
          payload: res,
        })
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.GET_WITHDRAW_MONEY_FAILED,
        })
        throw error?.response?.data?.error || error?.message
      })
  }
}

// Action to set withdraw money
export const postWithdrawMoney = (data, onSuccess, onFail) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.POST_WITHDRAW_MONEY_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/startup/withdraw-money`, data)
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
        }
        dispatch({
          type: actionTypes.POST_WITHDRAW_MONEY_SUCCESS,
          payload: res,
        })
        onSuccess && onSuccess(res)
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.POST_WITHDRAW_MONEY_FAILED,
        })
        onFail && onFail(error?.response?.data?.error || error?.message)
        throw error?.response?.data?.error || error?.message
      })
  }
}

// Action to get investors history
export const getInvestorHistory = data => {
  return async dispatch => {
    dispatch({
      type: actionTypes.GET_INVESTOR_HISTORY_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/startup/investment-history`, data)
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
        }
        dispatch({
          type: actionTypes.GET_INVESTOR_HISTORY_SUCCESS,
          payload: res,
        })
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.GET_INVESTOR_HISTORY_FAILED,
        })
        throw error?.response?.data?.error || error?.message
      })
  }
}

// Action to get investors details
export const getInvestorDetail = id => {
  return async dispatch => {
    dispatch({
      type: actionTypes.GET_INVESTOR_DETAIL_INIT,
    })

    await api
      .getwithReqForm(`${process.env.REACT_APP_API_URL}/startup/investor-detail?id=` + id)
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
        }
        dispatch({
          type: actionTypes.GET_INVESTOR_DETAIL_SUCCESS,
          payload: res.data,
        })
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.GET_INVESTOR_DETAIL_FAILED,
        })
        throw error?.response?.data?.error || error?.message
      })
  }
}

// Action to get profile completions data
export const getProfileCompletion = onSuccess => {
  return async dispatch => {
    dispatch({
      type: actionTypes.GET_PROFILE_COMPLETION_INIT,
    })

    await api
      .get(`${process.env.REACT_APP_API_URL}/startup/profile-completion`)
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
        }
        dispatch({
          type: actionTypes.GET_PROFILE_COMPLETION_SUCCESS,
          payload: res,
        })
        onSuccess && onSuccess()
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.GET_PROFILE_COMPLETION_FAILED,
        })
        throw error?.response?.data?.error || error?.message
      })
  }
}

// Action to update profile completions data
export const updateProfileCompletion = (payload, onSuccess, onFail) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.UPDATE_PROFILE_COMPLETION_INIT,
    })

    await api
      .post(`${process.env.REACT_APP_API_URL}/startup/profile-completion`, payload)
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
        }
        if (res?.result) {
          dispatch({
            type: actionTypes.UPDATE_PROFILE_COMPLETION_SUCCESS,
            payload: res,
          })
          onSuccess && onSuccess()
          return
        } else {
          dispatch({
            type: actionTypes.GET_PROFILE_COMPLETION_FAILED,
          })
          onFail && onFail(res?.error)
        }
      })
      .catch(error => {
        dispatch({
          type: actionTypes.GET_PROFILE_COMPLETION_FAILED,
        })
        onFail && onFail(error?.response?.data?.error || error?.message)
        throw error?.response?.data?.error || error?.message
      })
  }
}

// Action to get transfer fund data
export const getTransferFund = () => {
  return async dispatch => {
    dispatch({
      type: actionTypes.GET_TRANSFER_FUND_INIT,
    })

    await api
      .get(`${process.env.REACT_APP_API_URL}/startup/transfer-funds`)
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
        }
        dispatch({
          type: actionTypes.GET_TRANSFER_FUND_SUCCESS,
          payload: res,
        })
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.GET_TRANSFER_FUND_FAILED,
        })
        throw error?.response?.data?.error || error?.message
      })
  }
}

// Action to send invitations for fundraise
export const sendFundraiseInvite = (payload, onSuccess, onFail) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.SEND_FUNDRAISE_INVITE_INIT,
    })

    await api
      .post(`${process.env.REACT_APP_API_URL}/startup/fundraise-invite`, payload)
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
        }
        if (res?.result) {
          dispatch({
            type: actionTypes.SEND_FUNDRAISE_INVITE_SUCCESS,
          })
          onSuccess && onSuccess()
          return
        } else {
          dispatch({
            type: actionTypes.SEND_FUNDRAISE_INVITE_FAILED,
          })
          onFail && onFail(res?.error)
        }
      })
      .catch(error => {
        dispatch({
          type: actionTypes.SEND_FUNDRAISE_INVITE_FAILED,
        })
        onFail && onFail(error?.response?.data?.error || error?.message)
        throw error?.response?.data?.error || error?.message
      })
  }
}

// Action to get fundraise invite history
export const getFundraiseInviteHistory = (payload, onSuccess, onFail) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.GET_FUNDRAISE_INVITE_HISTORY_INIT,
    })

    await api
      .post(`${process.env.REACT_APP_API_URL}/startup/invite-history`, payload)
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
        }
        if (res?.result) {
          dispatch({
            type: actionTypes.GET_FUNDRAISE_INVITE_HISTORY_SUCCESS,
            payload: res,
          })
          onSuccess && onSuccess()

          return
        } else {
          dispatch({
            type: actionTypes.GET_FUNDRAISE_INVITE_HISTORY_FAILED,
          })
          onFail && onFail()
        }
      })
      .catch(error => {
        dispatch({
          type: actionTypes.SEND_FUNDRAISE_INVITE_FAILED,
        })
        onFail && onFail()
        throw error?.response?.data?.error || error?.message
      })
  }
}

// Action to get post history
export const getPostHistory = (payload, onSuccess, onFail) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.GET_POST_HISTORY_DATA_INIT,
    })

    await api
      .post(`${process.env.REACT_APP_API_URL}/startup/post-history-fundraise`, payload)
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
        }
        if (res?.result) {
          dispatch({
            type: actionTypes.GET_POST_HISTORY_DATA_SUCCESS,
            payload: res,
          })
          onSuccess && onSuccess()
          return
        } else {
          dispatch({
            type: actionTypes.GET_POST_HISTORY_DATA_FAILED,
          })
          onFail && onFail()
        }
      })
      .catch(error => {
        dispatch({
          type: actionTypes.GET_POST_HISTORY_DATA_FAILED,
        })
        onFail && onFail()
        throw error?.response?.data?.error || error?.fmessage
      })
  }
}

// Action to upload file
export const fileUploadURL = (data, onSuccess, onFail) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.GET_FILE_UPLOAD_URL_INIT,
    })
    await api
      .postwithFormData(`${process.env.REACT_APP_API_URL}/startup/file-upload-generate-url`, data)
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
        }
        if (res?.result) {
          dispatch({
            type: actionTypes.GET_FILE_UPLOAD_URL_SUCCESS,
            payload: res,
          })
          onSuccess && onSuccess(res)
          return
        } else {
          dispatch({
            type: actionTypes.GET_FILE_UPLOAD_URL_FAILED,
          })
          onFail && onFail(res?.error)
        }
      })
      .catch(error => {
        dispatch({
          type: actionTypes.GET_FILE_UPLOAD_URL_FAILED,
        })
        onFail && onFail(error?.response?.data?.error || error?.message)
        throw error?.response?.data?.error || error?.message
      })
  }
}

// Action to create a post
export const createFundraisePost = (payload, onSuccess, onFail) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.CREATE_FUNDRAISE_POST_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/startup/create-post-fundraise`, payload)
      .then(res => {
        if (!res?.result) {
          IsTokenExpired(res, dispatch)
        }
        if (res?.result) {
          dispatch({
            type: actionTypes.CREATE_FUNDRAISE_POST_SUCCESS,
            payload: res,
          })
          onSuccess && onSuccess(res)
          return
        } else {
          dispatch({
            type: actionTypes.CREATE_FUNDRAISE_POST_FAILED,
          })
          onFail && onFail(res?.error)
        }
      })
      .catch(error => {
        dispatch({
          type: actionTypes.CREATE_FUNDRAISE_POST_FAILED,
        })
        onFail && onFail(error?.response?.data?.error || error?.message)
        throw error?.response?.data?.error || error?.message
      })
  }
}

export const updateMissingTeamMembersCount = count => ({
  type: actionTypes.UPDATE_MISSING_TEAM_MEMBERS_COUNT,
  payload: count,
})
