export const GET_CUSTOMER_INFO_INIT = 'GET_CUSTOMER_INFO_INIT'
export const GET_CUSTOMER_INFO_SUCCESS = 'GET_CUSTOMER_INFO_SUCCESS'
export const GET_CUSTOMER_INFO_FAILED = 'GET_CUSTOMER_INFO_FAILED'
export const POST_ANSWER_INIT = 'POST_ANSWER_INIT'
export const POST_ANSWER_SUCCESS = 'POST_ANSWER_SUCCESS'
export const POST_ANSWER_FAILED = 'POST_ANSWER_FAILED'
export const CREATE_CUSTOMER_INIT = 'CREATE_CUSTOMER_INIT'
export const CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS'
export const CREATE_CUSTOMER_FAILED = 'CREATE_CUSTOMER_FAILED'
export const UPDATE_CUSTOMER_INIT = 'UPDATE_CUSTOMER_INIT'
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS'
export const UPDATE_CUSTOMER_FAILED = 'UPDATE_CUSTOMER_FAILED'
export const RETRIEVE_CUSTOMER_INIT = 'RETRIEVE_CUSTOMER_INIT'
export const RETRIEVE_CUSTOMER_SUCCESS = 'RETRIEVE_CUSTOMER_SUCCESS'
export const RETRIEVE_CUSTOMER_FAILED = 'RETRIEVE_CUSTOMER_FAILED'
export const SUBMIT_DOC_INIT = 'SUBMIT_DOC_INIT'
export const SUBMIT_DOC_SUCCESS = 'SUBMIT_DOC_SUCCESS'
export const SUBMIT_DOC_FAILED = 'SUBMIT_DOC_FAILED'
export const SET_ACCESS_TOKEN_INIT = 'SET_ACCESS_TOKEN_INIT'
export const SET_ACCESS_TOKEN_SUCCESS = 'SET_ACCESS_TOKEN_SUCCESS'
export const SET_ACCESS_TOKEN_FAILED = 'SET_ACCESS_TOKEN_FAILED'
export const ADD_FUNDING_ACCOUNT_INIT = 'ADD_FUNDING_ACCOUNT_INIT'
export const ADD_FUNDING_ACCOUNT_SUCCESS = 'ADD_FUNDING_ACCOUNT_SUCCESS'
export const ADD_FUNDING_ACCOUNT_FAILED = 'ADD_FUNDING_ACCOUNT_FAILED'
export const MANAGE_BANK_ACCOUNT_INIT = 'MANAGE_BANK_ACCOUNT_INIT'
export const MANAGE_BANK_ACCOUNT_SUCCESS = 'MANAGE_BANK_ACCOUNT_SUCCESS'
export const MANAGE_BANK_ACCOUNT_FAILED = 'MANAGE_BANK_ACCOUNT_FAILED'
export const DELETE_BANK_ACCOUNT_INIT = 'DELETE_BANK_ACCOUNT_INIT'
export const DELETE_BANK_ACCOUNT_SUCCESS = 'DELETE_BANK_ACCOUNT_SUCCESS'
export const DELETE_BANK_ACCOUNT_FAILED = 'DELETE_BANK_ACCOUNT_FAILED'
export const GET_WITHDRAW_MONEY_INIT = 'GET_WITHDRAW_MONEY_INIT'
export const GET_WITHDRAW_MONEY_SUCCESS = 'GET_WITHDRAW_MONEY_SUCCESS'
export const GET_WITHDRAW_MONEY_FAILED = 'GET_WITHDRAW_MONEY_FAILED'
export const POST_WITHDRAW_MONEY_INIT = 'POST_WITHDRAW_MONEY_INIT'
export const POST_WITHDRAW_MONEY_SUCCESS = 'POST_WITHDRAW_MONEY_SUCCESS'
export const POST_WITHDRAW_MONEY_FAILED = 'POST_WITHDRAW_MONEY_FAILED'
export const GET_DEPOSIT_MONEY_INIT = 'GET_DEPOSIT_MONEY_INIT'
export const GET_DEPOSIT_MONEY_SUCCESS = 'GET_DEPOSIT_MONEY_SUCCESS'
export const GET_DEPOSIT_MONEY_FAILED = 'GET_DEPOSIT_MONEY_FAILED'
export const POST_DEPOSIT_MONEY_INIT = 'POST_DEPOSIT_MONEY_INIT'
export const POST_DEPOSIT_MONEY_SUCCESS = 'POST_DEPOSIT_MONEY_SUCCESS'
export const POST_DEPOSIT_MONEY_FAILED = 'POST_DEPOSIT_MONEY_FAILED'
export const GET_REFILL_MONEY_INIT = 'GET_REFILL_MONEY_INIT'
export const GET_REFILL_MONEY_SUCCESS = 'GET_REFILL_MONEY_SUCCESS'
export const GET_REFILL_MONEY_FAILED = 'GET_REFILL_MONEY_FAILED'
export const POST_REFILL_MONEY_INIT = 'POST_REFILL_MONEY_INIT'
export const POST_REFILL_MONEY_SUCCESS = 'POST_REFILL_MONEY_SUCCESS'
export const POST_REFILL_MONEY_FAILED = 'POST_REFILL_MONEY_FAILED'
export const POST_LINK_TOKEN_INIT = 'POST_LINK_TOKEN_INIT'
export const POST_LINK_TOKEN_SUCCESS = 'POST_LINK_TOKEN_SUCCESS'
export const POST_LINK_TOKEN_FAILED = 'POST_LINK_TOKEN_FAILED'
export const POST_INVESTING_STEPS_INIT = 'POST_INVESTING_STEPS_INIT'
export const POST_INVESTING_STEPS_SUCCESS = 'POST_INVESTING_STEPS_SUCCESS'
export const POST_INVESTING_STEPS_FAILED = 'POST_INVESTING_STEPS_FAILED'
export const GET_INVEST_MONEY_INIT = 'GET_INVEST_MONEY_INIT'
export const GET_INVEST_MONEY_SUCCESS = 'GET_INVEST_MONEY_SUCCESS'
export const GET_INVEST_MONEY_FAILED = 'GET_INVEST_MONEY_FAILED'
export const GET_COMPLETED_TOUR_INIT = 'GET_COMPLETED_TOUR_INIT'
export const GET_COMPLETED_TOUR_SUCCESS = 'GET_COMPLETED_TOUR_SUCCESS'
export const GET_COMPLETED_TOUR_FAILED = 'GET_COMPLETED_TOUR_FAILED'
export const DONE_SUCCESS = 'DONE_SUCCESS'
export const GET_TOUR_TOKEN_INIT = 'GET_TOUR_TOKEN_INIT'
export const GET_TOUR_TOKEN_SUCCESS = 'GET_TOUR_TOKEN_SUCCESS'
export const GET_TOUR_TOKEN_FAILED = 'GET_TOUR_TOKEN_FAILED'
export const GET_INVESTMENT_HISTORY_INIT = 'GET_INVESTMENT_HISTORY_INIT'
export const GET_INVESTMENT_HISTORY_SUCCESS = 'GET_INVESTMENT_HISTORY_SUCCESS'
export const GET_INVESTMENT_HISTORY_FAILED = 'GET_INVESTMENT_HISTORY_FAILED'
export const TAX_INFORMATION_DATA = 'TAX_INFORMATION_DATA'
export const SET_CURRENT = 'SET_CURRENT'
export const IS_DEBOUNCE_LOADER = 'IS_DEBOUNCE_LOADER'
export const UPDATE_INVESTING_TYPE_INIT = 'UPDATE_INVESTING_TYPE_INIT'
export const UPDATE_INVESTING_TYPE_SUCCESS = 'UPDATE_INVESTING_TYPE_SUCCESS'
export const UPDATE_INVESTING_TYPE_FAILED = 'UPDATE_INVESTING_TYPE_FAILED'
export const UPDATE_BUSINESS_TYPE_INIT = 'UPDATE_BUSINESS_TYPE_INIT'
export const UPDATE_BUSINESS_TYPE_SUCCESS = 'UPDATE_BUSINESS_TYPE_SUCCESS'
export const UPDATE_BUSINESS_TYPE_FAILED = 'UPDATE_BUSINESS_TYPE_FAILED'
export const GET_INDUSTRY_CLASSIFICATIONS_INIT = 'GET_INDUSTRY_CLASSIFICATIONS_INIT'
export const GET_INDUSTRY_CLASSIFICATIONS_SUCCESS = 'GET_INDUSTRY_CLASSIFICATIONS_SUCCESS'
export const GET_INDUSTRY_CLASSIFICATIONS_FAILED = 'GET_INDUSTRY_CLASSIFICATIONS_FAILED'
export const GET_BUSINESS_CLASSIFICATIONS_INIT = 'GET_BUSINESS_CLASSIFICATIONS_INIT'
export const GET_BUSINESS_CLASSIFICATIONS_SUCCESS = 'GET_BUSINESS_CLASSIFICATIONS_SUCCESS'
export const GET_BUSINESS_CLASSIFICATIONS_FAILED = 'GET_BUSINESS_CLASSIFICATIONS_FAILED'
export const CREATE_DWOLLA_CUSTOMER_INIT = 'CREATE_DWOLLA_CUSTOMER_INIT'
export const CREATE_DWOLLA_CUSTOMER_SUCCESS = 'CREATE_DWOLLA_CUSTOMER_SUCCESS'
export const CREATE_DWOLLA_CUSTOMER_FAILED = 'CREATE_DWOLLA_CUSTOMER_FAILED'
export const UPDATE_DWOLLA_CUSTOMER_INIT = 'UPDATE_DWOLLA_CUSTOMER_INIT'
export const UPDATE_DWOLLA_CUSTOMER_SUCCESS = 'UPDATE_DWOLLA_CUSTOMER_SUCCESS'
export const UPDATE_DWOLLA_CUSTOMER_FAILED = 'UPDATE_DWOLLA_CUSTOMER_FAILED'
export const CERTIFY_USER_INIT = 'CERTIFY_USER_INIT'
export const CERTIFY_USER_SUCCESS = 'CERTIFY_USER_SUCCESS'
export const CERTIFY_USER_FAILED = 'CERTIFY_USER_FAILED'
export const UPDATE_BENEFICIAL_OWNER_EXISTS_INIT = 'UPDATE_BENEFICIAL_OWNER_EXISTS_INIT'
export const UPDATE_BENEFICIAL_OWNER_EXISTS_SUCCESS = 'UPDATE_BENEFICIAL_OWNER_EXISTS_SUCCESS'
export const UPDATE_BENEFICIAL_OWNER_EXISTS_FAILED = 'UPDATE_BENEFICIAL_OWNER_EXISTS_FAILED'
export const GET_BENEFICIAL_OWNER_INIT = 'GET_BENEFICIAL_OWNER_INIT'
export const GET_BENEFICIAL_OWNER_SUCCESS = 'GET_BENEFICIAL_OWNER_SUCCESS'
export const GET_BENEFICIAL_OWNER_FAILED = 'GET_BENEFICIAL_OWNER_FAILED'
export const ADD_BENEFICIAL_OWNER_INIT = 'ADD_BENEFICIAL_OWNER_INIT'
export const ADD_BENEFICIAL_OWNER_SUCCESS = 'ADD_BENEFICIAL_OWNER_SUCCESS'
export const ADD_BENEFICIAL_OWNER_FAILED = 'ADD_BENEFICIAL_OWNER_FAILED'
export const UPDATE_BENEFICIAL_OWNER_INIT = 'UPDATE_BENEFICIAL_OWNER_INIT'
export const UPDATE_BENEFICIAL_OWNER_SUCCESS = 'UPDATE_BENEFICIAL_OWNER_SUCCESS'
export const UPDATE_BENEFICIAL_OWNER_FAILED = 'UPDATE_BENEFICIAL_OWNER_FAILED'
export const REMOVE_BENEFICIAL_OWNER_INIT = 'REMOVE_BENEFICIAL_OWNER_INIT'
export const REMOVE_BENEFICIAL_OWNER_SUCCESS = 'REMOVE_BENEFICIAL_OWNER_SUCCESS'
export const REMOVE_BENEFICIAL_OWNER_FAILED = 'REMOVE_BENEFICIAL_OWNER_FAILED'
export const SUBMIT_BENEFICIAL_DOC_INIT = 'SUBMIT_BENEFICIAL_DOC_INIT'
export const SUBMIT_BENEFICIAL_DOC_SUCCESS = 'SUBMIT_BENEFICIAL_DOC_SUCCESS'
export const SUBMIT_BENEFICIAL_DOC_FAILED = 'SUBMIT_BENEFICIAL_DOC_FAILED'
export const GET_TAX_INFO_INIT = 'GET_TAX_INFO_INIT'
export const GET_TAX_INFO_SUCCESS = 'GET_TAX_INFO_SUCCESS'
export const GET_TAX_INFO_FAILED = 'GET_TAX_INFO_FAILED'
export const SUBMIT_TAX_INFO_INIT = 'SUBMIT_TAX_INFO_INIT'
export const SUBMIT_TAX_INFO_SUCCESS = 'SUBMIT_TAX_INFO_SUCCESS'
export const SUBMIT_TAX_INFO_FAILED = 'SUBMIT_TAX_INFO_FAILED'

export const GET_PERSONA_INFO_INIT = 'GET_PERSONA_INFO_INIT'
export const GET_PERSONA_INFO_SUCCESS = 'GET_PERSONA_INFO_SUCCESS'
export const GET_PERSONA_INFO_FAILED = 'GET_PERSONA_INFO_FAILED'

export const TAX_FORM_FILE_UPLOAD_INIT = 'TAX_FORM_FILE_UPLOAD_INIT'
export const TAX_FORM_FILE_UPLOAD_SUCCESS = 'TAX_FORM_FILE_UPLOAD_SUCCESS'
export const TAX_FORM_FILE_UPLOAD_FAILED = 'TAX_FORM_FILE_UPLOAD_FAILED'

export const GET_ACCREDITATION_INFO_INIT = 'GET_ACCREDITATION_INFO_INIT'
export const GET_ACCREDITATION_INFO_SUCCESS = 'GET_ACCREDITATION_INFO_SUCCESS'
export const GET_ACCREDITATION_INFO_FAILED = 'GET_ACCREDITATION_INFO_FAILED'

export const SUBMIT_ACCREDITATION_INFO_INIT = 'SUBMIT_ACCREDITATION_INFO_INIT'
export const SUBMIT_ACCREDITATION_INFO_SUCCESS = 'SUBMIT_ACCREDITATION_INFO_SUCCESS'
export const SUBMIT_ACCREDITATION_INFO_FAILED = 'SUBMIT_ACCREDITATION_INFO_FAILED'
