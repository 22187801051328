import { LOGOUT_LOCAL_INVESTOR_USER } from '../auth/authActions'
import * as actionTypes from './ActionTypes'
import {
  UPDATE_STEP,
  UPDATE_TAB,
  LOGIN_FAILED,
  SIGNUP_FAILED,
  UPDATE_USER_INFO_FAILED,
  DID_ENTER_REGISTER_PAGE,
  RECEIVE_USER_PROFILE,
  EDIT_PHOTO_START,
  RECEIVE_USER_IMAGE,
  UPDATE_INDEX,
  USER_IMAGE,
  CHANGE_REFER_INFO,
} from './investorFlowControlActions'
import {
  REQUEST_SIGNIN_BY_EMAIL,
  REQUEST_SIGNUP_BY_EMAIL,
  RECEIVE_SIGNIN_BY_EMAIL,
} from '../auth/authActions'
import moment from 'moment'

const INITIAL_STATE = {
  loading: false,
  loginFailed: false,
  signupFailed: false,
  updateUserInfoFailed: false,
  confirmedEmail: false,
  addedSyndicate: false,
  searchField: '',
  showSyndicateForm: false,
  stepInvestor: 0,
  tabInvestor: 0,
  angelGroupInput: '',
  user: {
    isGoogleLogged: false,
    googleProfileObj: {},
  },
  profile_pic_link: '',
  dashboardView: 'discover',
  historySelection: 'all',
  userInfos: [],
  userHistory: [],
  userHistoryConnected: [],
  userHistoryPassed: [],
  index: 0,
  imageUri: '',
  companyPhotoLoading: false,
  companyLogoModal: false,
  referSent: false,
  feedbackModal: false,
  invitedStartup: null,
  loadingDashboard: false,
  userHistoryInfo: [],
  investorDeals: [],
  investorDetail: null,
  hideInvestorLoadButton: false,
  investorPageNum: 1,
  userHistoryStatus: null,
  slideDeckModal: false,
  slideDeckSelected: null,
  connectedWithStartup: false,
  selectedCoFounder: {
    bio: '',
    link: '',
    name: '',
    photo: '',
    position: [],
  },
  cofounderSeeMore: {},
  saveAndExitClicked: false,
  saveAndExitClicked: false,
  passDeal: {
    reason: '',
    issue: '',
    feedback: '',
    submitPage: false,
    anonymous: false,
  },
  investDeal: {
    investorName: 'self',
    usSSN: false,
  },
  investorDealQuestions: [],
  investorMonthlyUpdates: [],
  investorPassedDeals: [],
  investorPassedDealsTotalCount: 0,
  get_customer_info: {},
  get_beneficial_owners_data: [],
  get_customer_status: {},
  get_doc_status: {},
  manage_bank_acc_data: {},
  bankAccounts: [],
  get_withdraw_money: {},
  get_deposit_money: {},
  set_access_token_data: {},
  create_link_token_data: {},
  get_refill_money: {},
  investment_value: '',
  investorInfo: {
    legalName: '',
    firstName: '',
    lastName: '',
    isTrustee: false,
    dateOfBirth: null,
    phoneNo: '',
    address: '',
    zipCode: '',
    ssn: '',
    firmType: '',
    isControlPerson: false,
    isBeneficialOwner: false,
  },
  investorInfoNoSSN: {
    taxPayerNo: '',
    country: '',
    city: '',
    state: '',
  },
  accreditionType: '',
  localAccreditionLevel: '',
  investorLegalNameInfo: {
    noOfOwners: '',
    isErisa: false,
    isUSFia: false,
    title: '',
    ein: '',
    address: '',
    zipCode: '',
    city: '',
    country: '',
    state: '',
    countryIncorporation: '',
    stateIncorporation: '',
  },
  investorBankId: '',
  add_funding_account: {},
  post_investing_steps_data: {},
  get_invest_money_data: {},
  get_completed_tour_data: {},
  get_tour_token_data: {},
  done_status: false,
  investorLoader: true,
  investment_history: [],
  totalInvestmentHistoryRecords: 0,
  isLoadingBankAccount: false,
  dealsQuestionLoading: false,
  investorKarmaPoints: 0,
  closing_docs: [],
  increase_investment_error: null,
  taxesInformation: null,
  tax_info: null,
  current: 0,
  is_debounce_loader: false,
  investorDealsCount: 0,
  industry_category: [],
  business_category: [],
  metric_data: [],
  min_invested: 0,
  max_invested: 0,
  profileSettingsActiveTabKey: 'banking',
  get_accreditation_info: {},
}

const investorFlowControlReducer = (state = INITIAL_STATE, action) => {
  const { type, payload, page, totalCount, karma_points } = action
  switch (type) {
    case 'SET_COFOUNDER_SEEMORE': {
      return {
        ...state,
        cofounderSeeMore: action.payload,
      }
    }
    case 'SET_SELECTED_INV_COFOUNDER': {
      return {
        ...state,
        selectedCoFounder: action.payload,
      }
    }
    case 'SET_CONNECTED_STARTUP': {
      return {
        ...state,
        connectedWithStartup: action.payload,
      }
    }
    case 'SET_SLIDE_DECK_MODAL': {
      return {
        ...state,
        slideDeckModal: action.payload,
      }
    }
    case 'SET_SELECTED_SLIDE_DECK': {
      return {
        ...state,
        slideDeckSelected: action.payload,
      }
    }
    case 'CLEAR_INVESTOR_FLOW':
      return INITIAL_STATE
    case 'SET_PASS_DEAL_REASON':
      return {
        ...state,
        passDeal: {
          ...state.passDeal,
          reason: payload,
          issue: '',
        },
      }
    case 'SET_PASS_DEAL_ISSUE':
      return {
        ...state,
        passDeal: {
          ...state.passDeal,
          issue: payload,
        },
      }
    case 'SET_PASS_DEAL_FEEDBACK':
      return {
        ...state,
        passDeal: {
          ...state.passDeal,
          feedback: payload,
        },
      }
    case 'SET_PASS_DEAL_ANONYMOUS':
      return {
        ...state,
        passDeal: {
          ...state.passDeal,
          anonymous: payload,
        },
      }
    case 'SET_PASS_DEAL_SUBMIT_PAGE':
      return {
        ...state,
        passDeal: {
          ...state.passDeal,
          submitPage: payload,
        },
      }
    case 'CLEAR_PASS_DEAL':
      return {
        ...state,
        passDeal: INITIAL_STATE.passDeal,
      }
    case 'SET_INVESTOR_NAME':
      return {
        ...state,
        investDeal: {
          ...state.investDeal,
          investorName: payload,
        },
      }
    case 'SET_US_SSN':
      return {
        ...state,
        investDeal: {
          ...state.investDeal,
          usSSN: payload,
        },
      }
    case 'SET_CONFIRMED_EMAIL':
      return {
        ...state,
        confirmedEmail: true,
      }
    case 'SET_USER_HISTORY_STATUS':
      return {
        ...state,
        userHistoryStatus: payload,
      }
    case 'RECEIVE_INV_USER_HISTORY_CARD_INFO':
      return {
        ...state,
        userHistoryInfo: payload,
      }
    case 'SET_INVESTOR_LOADER':
      return {
        ...state,
        investorLoader: true,
      }
    case 'SET_HIDE_INVESTOR_LOADER':
      return {
        ...state,
        investorLoader: false,
      }
    case 'GET_INVESTOR_DEALS':
      return {
        ...state,
        investorLoader: false,
        investorDeals: state.investorDeals.concat(payload),
        investorPageNum: page,
        investorDealsCount: totalCount,
      }
    case 'GET_INVESTOR_DEALS_LIST':
      return {
        ...state,
        investorLoader: false,
        investorDeals: payload,
        investorPageNum: page,
        investorDealsCount: totalCount,
      }
    case 'CLEAR_INVESTOR_DEALS':
      return {
        ...state,
        investorLoader: false,
        investorDeals: [],
        investorPageNum: 1,
        hideInvestorLoadButton: false,
      }
    case 'SET_INVESTOR_DEAL_QUESTION_LOADER':
      return {
        ...state,
        dealsQuestionLoading: payload,
      }
    case 'GET_INVESTOR_DEAL_QUESTION':
      return {
        ...state,
        investorDealQuestions: payload,
        dealsQuestionLoading: false,
      }

    case 'GET_INVESTOR_MONTHLY_UPDATES':
      return {
        ...state,
        investorMonthlyUpdates: payload,
      }

    case 'GET_INVESTOR_MONTHLY_UPDATES_METRIC_DATA':
      return {
        ...state,
        metric_data: payload,
      }

    case 'GET_INVESTOR_PASSED_DEALS':
      return {
        ...state,
        investorPassedDeals: payload,
        investorPassedDealsTotalCount: totalCount,
        investorKarmaPoints: karma_points,
        loading: false,
      }
    case 'GET_INVESTOR_KARMA_POINTS':
      return {
        ...state,
        investorKarmaPoints: payload,
        loading: false,
      }
    case 'SET_HIDE_INVESTOR_LOAD_BUTTON':
      return {
        ...state,
        hideInvestorLoadButton: payload,
      }
    case 'GET_INVESTOR_DEAL_DETAIL':
      return {
        ...state,
        investorDetail: payload,
      }
    case 'REMOVE_STARTUP':
      return {
        ...state,
        userInfos: [...state.userInfos.slice(1)],
      }
    case 'SET_COMPANY_LOGO_MODAL':
      return {
        ...state,
        companyLogoModal: payload,
      }
    case 'SET_COMPANY_LOGO_LOADING':
      return {
        ...state,
        companyPhotoLoading: payload,
        companyLogoModal: true,
      }
    case 'LOAD_INV_DASHBOARD':
      return {
        ...state,
        loadingDashboard: payload,
      }
    case 'SET_INVITED_STARTUP':
      return {
        ...state,
        invitedStartup: payload,
      }

    case 'RECEIVE_INV_USER_HISTORY':
      return {
        ...state,
        userHistory: payload,
        loading: false,
      }

    case 'RECEIVE_INV_USER_HISTORY_CONNECTED':
      return {
        ...state,
        userHistoryConnected: payload,
        loading: false,
      }

    case 'RECEIVE_INV_USER_HISTORY_PASSED':
      return {
        ...state,
        userHistoryPassed: payload,
        loading: false,
      }
    case 'SET_INV_HISTORY_SELECTION':
      return {
        ...state,
        historySelection: payload,
      }
    case 'CHANGE_SEARCHFIELD':
      return { ...state, searchField: action.payload }

    case 'SET_FEEDBACK_MODAL':
      return { ...state, feedbackModal: action.payload }

    case 'REMOVE_SEARCHFIELD':
      return { ...state, searchField: '' }

    case 'ANGELGROUP_INPUT':
      return { ...state, angelGroupInput: action.payload }

    case 'REMOVE_ANGEL_INPUT':
      return { ...state, angelGroupInput: '' }

    case 'PREV_STEP':
      return {
        ...state,
        stepInvestor: state.stepInvestor - 1,
      }

    case 'PREV_STEP_INVESTOR':
      return {
        ...state,
        signupFailed: false,
        stepInvestor: state.stepInvestor - 1,
      }

    case 'PREV_STEP_RESET':
      return {
        ...state,
        stepInvestor: state.stepInvestor - 1,
        user: {
          ...state.user,
          sectors: action.text,
        },
      }

    case 'RESET_INITIAL_STATE':
      return {
        ...state,
        loginFailed: false,
        signupFailed: false,
        updateUserInfoFailed: false,
        fileUploaded: false,
        showSlideDeckForm: false,
        searchField: '',
        stepInvestor: 0,
        tabInvestor: 0,
        user: {
          ...state.user,
          isGoogleLogged: false,
          googleProfileObj: {},
        },
      }

    case 'NEXT_STEP':
      return {
        ...state,
        stepInvestor: state.stepInvestor + 1,
      }

    case 'NEXT_STEP_INVESTOR':
      return {
        ...state,
        stepInvestor: state.stepInvestor + 1,
      }

    case 'ADDED_SYNDICATE':
      return {
        ...state,
        addedSyndicate: true,
      }

    case 'SHOW_SYNDICATE_FORM':
      return {
        ...state,
        showSyndicateForm: !state.showSyndicateForm,
      }

    case 'RETURN_HOME_PAGE':
      return {
        ...state,
        loginFailed: false,
        updateUserInfoFailed: false,
        searchField: '',
        showSyndicateForm: false,
        signupFailed: false,
        stepInvestor: 0,
        tabInvestor: 0,
        user: {
          ...state.user,
          isGoogleLogged: false,
          googleProfileObj: {},
        },
      }

    case 'GOOGLE_LOGIN_FIELD':
      return {
        ...state,
        user: {
          ...state.user,
          googleProfileObj: action.text,
        },
      }

    case 'SUCCESSFUL_GOOGLE_LOGIN':
      return {
        ...state,
        stepInvestor: state.stepInvestor + 1,
        user: {
          ...state.user,
          isGoogleLogged: action.text,
        },
      }

    case 'USER_IS_NOT_ACCREDITED':
      return {
        ...state,
        stepInvestor: state.stepInvestor + 2,
      }

    case 'SET_INV_DISCOVER_VIEW':
      return {
        ...state,
        dashboardView: 'discover',
      }

    case 'SET_INV_HISTORY_VIEW':
      return {
        ...state,
        dashboardView: 'history',
      }
    case 'Save_And_Exit_Clicked': {
      return {
        ...state,
        saveAndExitClicked: true,
      }
    }
    case 'Save_And_Exit_Clicked_Reset': {
      return {
        ...state,
        saveAndExitClicked: false,
      }
    }
    case LOGOUT_LOCAL_INVESTOR_USER:
      return INITIAL_STATE

    case UPDATE_STEP:
      return {
        ...state,
        stepInvestor: payload,
      }

    case UPDATE_TAB:
      return {
        ...state,
        tabInvestor: payload,
      }

    case LOGIN_FAILED:
      return {
        ...state,
        loginFailed: true,
        signupFailed: false,
        updateUserInfoFailed: false,
        addedSyndicate: false,
        searchField: '',
        showSyndicateForm: false,
        angelGroupInput: '',
        user: {
          isGoogleLogged: false,
          googleProfileObj: {},
        },
        profile_pic_link: '',
        dashboardView: 'discover',
        historySelection: 'all',
        userInfos: [],
        index: 0,
        imageUri: '',
        referSent: false,
        feedbackModal: false,
      }

    case SIGNUP_FAILED:
      return {
        ...state,
        signupFailed: true,
        loginFailed: false,
        updateUserInfoFailed: false,
        confirmedEmail: false,
        addedSyndicate: false,
        searchField: '',
        showSyndicateForm: false,
        angelGroupInput: '',
        user: {
          isGoogleLogged: false,
          googleProfileObj: {},
        },
        profile_pic_link: '',
        dashboardView: 'discover',
        historySelection: 'all',
        userInfos: [],
        index: 0,
        imageUri: '',
        referSent: false,
        feedbackModal: false,
      }

    case UPDATE_USER_INFO_FAILED:
      return {
        ...state,
        updateUserInfoFailed: true,
      }

    case REQUEST_SIGNIN_BY_EMAIL:
      return {
        ...state,
        loginFailed: false,
      }

    case REQUEST_SIGNUP_BY_EMAIL:
      return {
        ...state,
        signupFailed: false,
      }

    case DID_ENTER_REGISTER_PAGE:
      return {
        ...state,
        signupFailed: false,
      }

    case RECEIVE_SIGNIN_BY_EMAIL:
      return {
        ...state,
        confirmedEmail: payload.user.email_confirmed,
      }

    case RECEIVE_USER_PROFILE:
      return {
        ...state,
        userInfos: payload,
        loading: false,
      }

    case EDIT_PHOTO_START:
      return {
        ...state,
        loading: true,
      }

    case RECEIVE_USER_IMAGE: {
      return {
        ...state,
        profile_pic_link: payload,
      }
    }
    case UPDATE_INDEX: {
      return {
        ...state,
        index: state.index + 1,
      }
    }
    case USER_IMAGE: {
      return {
        ...state,
        imageUri: action.payload,
      }
    }
    case CHANGE_REFER_INFO: {
      return {
        ...state,
        referSent: true,
      }
    }

    case actionTypes.GET_CUSTOMER_INFO_INIT:
      return {
        ...state,
        isLoadingBankAccount: true,
      }
    case actionTypes.GET_CUSTOMER_INFO_SUCCESS:
      return {
        ...state,
        isLoadingBankAccount: false,
        get_customer_info: action.payload,
      }
    case actionTypes.GET_CUSTOMER_INFO_FAILED:
      return {
        ...state,
        isLoadingBankAccount: false,
      }
    case actionTypes.CREATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        get_customer_status: action.payload,
      }
    case actionTypes.SUBMIT_DOC_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.SUBMIT_DOC_SUCCESS:
      return {
        ...state,
        loading: false,
        get_doc_status: action.payload?.document_status,
        // get_customer_info: action.payload,
      }
    case actionTypes.SUBMIT_DOC_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.MANAGE_BANK_ACCOUNT_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.MANAGE_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        manage_bank_acc_data: action.payload,
      }
    case actionTypes.MANAGE_BANK_ACCOUNT_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.DELETE_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.GET_WITHDRAW_MONEY_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.GET_WITHDRAW_MONEY_SUCCESS:
      return {
        ...state,
        loading: false,
        get_withdraw_money: action.payload,
      }
    case actionTypes.GET_WITHDRAW_MONEY_FAILED:
      return {
        ...state,
        loading: false,
      }

    case actionTypes.GET_DEPOSIT_MONEY_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.GET_DEPOSIT_MONEY_SUCCESS:
      return {
        ...state,
        loading: false,
        get_deposit_money: action.payload,
      }
    case actionTypes.GET_DEPOSIT_MONEY_FAILED:
      return {
        ...state,
        loading: false,
      }

    case actionTypes.GET_REFILL_MONEY_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.GET_REFILL_MONEY_SUCCESS:
      return {
        ...state,
        loading: false,
        get_refill_money: action.payload,
      }
    case actionTypes.GET_REFILL_MONEY_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.SET_ACCESS_TOKEN_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.SET_ACCESS_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        set_access_token_data: action.payload,
      }
    case actionTypes.SET_ACCESS_TOKEN_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.POST_LINK_TOKEN_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.POST_LINK_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        create_link_token_data: action.payload,
      }
    case actionTypes.POST_LINK_TOKEN_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.ADD_FUNDING_ACCOUNT_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.ADD_FUNDING_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        add_funding_account: action.payload,
      }
    case actionTypes.ADD_FUNDING_ACCOUNT_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.POST_INVESTING_STEPS_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.POST_INVESTING_STEPS_SUCCESS:
      return {
        ...state,
        loading: false,
        post_investing_steps_data: action.payload,
      }
    case actionTypes.GET_INVEST_MONEY_SUCCESS:
      return {
        ...state,
        loading: false,
        get_invest_money_data: action.payload,
      }
    case actionTypes.POST_INVESTING_STEPS_FAILED:
      return {
        ...state,
        loading: false,
        increase_investment_error: action.error,
      }
    case 'SET_INVESTMENT_VALUE':
      return {
        ...state,
        investment_value: action.payload,
      }
    case 'SET_INVESTOR_INFO':
      return {
        ...state,
        investorInfo: action.payload,
      }
    case 'SET_INVESTOR_INFO_NO_SSN':
      return {
        ...state,
        investorInfoNoSSN: action.payload,
      }
    case 'SET_INVESTOR_LEGAL_NAME_INFO':
      return {
        ...state,
        investorLegalNameInfo: action.payload,
      }
    case 'SET_INVESTOR_BANK_ID':
      return {
        ...state,
        investorBankId: action.payload,
      }
    case 'SET_ACCREDITION_TYPE':
      return {
        ...state,
        accreditionType: action.payload,
      }
    case 'SET_LOCAL_ACCREDITION_LEVEL':
      return {
        ...state,
        localAccreditionLevel: action.payload,
      }
    case 'REMOVE_ACCOUNT_ERROR':
      return {
        ...state,
        add_funding_account: {},
      }
    case 'REMOVE_POST_MONEY_ERROR':
      return {
        ...state,
        get_invest_money_data: {},
      }

    case actionTypes.DONE_SUCCESS:
      return {
        ...state,
        done_status: true,
      }
    case actionTypes.IS_DEBOUNCE_LOADER:
      return {
        ...state,
        is_debounce_loader: action.payload,
      }
    case actionTypes.TAX_INFORMATION_DATA:
      return {
        ...state,
        taxesInformation: action.payload,
      }
    case actionTypes.SET_CURRENT:
      return {
        ...state,
        current: action.payload,
      }

    case actionTypes.GET_COMPLETED_TOUR_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.GET_COMPLETED_TOUR_SUCCESS:
      return {
        ...state,
        loading: false,
        get_completed_tour_data: action.payload,
      }
    case actionTypes.GET_COMPLETED_TOUR_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.GET_TOUR_TOKEN_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.GET_TOUR_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        get_tour_token_data: action.payload,
      }
    case actionTypes.GET_TOUR_TOKEN_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.GET_INVESTMENT_HISTORY_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.GET_INVESTMENT_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        investment_history: action.payload,
        totalInvestmentHistoryRecords: action.totalCount,
        bankAccounts: action.bankAccounts,
        min_invested: action.min_invested,
        max_invested: action.max_invested,
      }
    case actionTypes.GET_INVESTMENT_HISTORY_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.UPDATE_INVESTING_TYPE_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.UPDATE_INVESTING_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.UPDATE_INVESTING_TYPE_FAILED:
      return {
        ...state,
        loading: false,
      }

    case actionTypes.UPDATE_BUSINESS_TYPE_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.UPDATE_BUSINESS_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.UPDATE_BUSINESS_TYPE_FAILED:
      return {
        ...state,
        loading: false,
      }

    case actionTypes.GET_INDUSTRY_CLASSIFICATIONS_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.GET_INDUSTRY_CLASSIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        industry_category: action.payload,
      }
    case actionTypes.GET_INDUSTRY_CLASSIFICATIONS_FAILED:
      return {
        ...state,
        loading: false,
        industry_category: [],
      }
    case actionTypes.GET_BUSINESS_CLASSIFICATIONS_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.GET_BUSINESS_CLASSIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        business_category: action.payload,
      }
    case actionTypes.GET_BUSINESS_CLASSIFICATIONS_FAILED:
      return {
        ...state,
        loading: false,
        business_category: [],
      }

    case actionTypes.CREATE_DWOLLA_CUSTOMER_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.CREATE_DWOLLA_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.CREATE_DWOLLA_CUSTOMER_FAILED:
      return {
        ...state,
        loading: false,
        business_category: [],
      }

    case actionTypes.UPDATE_DWOLLA_CUSTOMER_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.UPDATE_DWOLLA_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.UPDATE_DWOLLA_CUSTOMER_FAILED:
      return {
        ...state,
        loading: false,
      }

    case actionTypes.CERTIFY_USER_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.CERTIFY_USER_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.CERTIFY_USER_FAILED:
      return {
        ...state,
        loading: false,
      }

    case actionTypes.UPDATE_BENEFICIAL_OWNER_EXISTS_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.UPDATE_BENEFICIAL_OWNER_EXISTS_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.UPDATE_BENEFICIAL_OWNER_EXISTS_FAILED:
      return {
        ...state,
        loading: false,
      }

    case actionTypes.GET_BENEFICIAL_OWNER_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.GET_BENEFICIAL_OWNER_SUCCESS:
      return {
        ...state,
        loading: false,
        get_beneficial_owners_data: action.payload,
      }
    case actionTypes.GET_BENEFICIAL_OWNER_FAILED:
      return {
        ...state,
        loading: false,
      }

    case actionTypes.ADD_BENEFICIAL_OWNER_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.ADD_BENEFICIAL_OWNER_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.ADD_BENEFICIAL_OWNER_FAILED:
      return {
        ...state,
        loading: false,
      }

    case actionTypes.UPDATE_BENEFICIAL_OWNER_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.UPDATE_BENEFICIAL_OWNER_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.UPDATE_BENEFICIAL_OWNER_FAILED:
      return {
        ...state,
        loading: false,
      }

    case actionTypes.REMOVE_BENEFICIAL_OWNER_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.REMOVE_BENEFICIAL_OWNER_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.REMOVE_BENEFICIAL_OWNER_FAILED:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.SUBMIT_BENEFICIAL_DOC_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.SUBMIT_BENEFICIAL_DOC_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.SUBMIT_BENEFICIAL_DOC_FAILED:
      return {
        ...state,
        loading: false,
      }
    case 'ADD_INVESTING_STEPS_DATA':
      const { step, data } = action.payload
      return {
        ...state,
        post_investing_steps_data: {
          ...state.post_investing_steps_data,
          [step]: data,
        },
      }

    case 'ADD_ALL_INVESTING_STEPS_DATA':
      return {
        ...state,
        post_investing_steps_data: action.payload,
      }
    case 'REMOVE_INVESTING_STEPS_DATA':
      return {
        ...state,
        post_investing_steps_data: {},
      }

    case 'EMPTY_BUSINESS_CATEGORIES':
      return {
        ...state,
        business_category: [],
      }

    case actionTypes.GET_TAX_INFO_INIT:
      return {
        ...state,
        loading: true,
      }

    case actionTypes.GET_TAX_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        tax_info: action.payload,
      }

    case actionTypes.GET_TAX_INFO_FAILED:
      return {
        ...state,
        loading: false,
      }

    case actionTypes.SUBMIT_TAX_INFO_INIT:
      return {
        ...state,
        loading: true,
      }

    case actionTypes.SUBMIT_TAX_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        tax_info: action.payload,
      }

    case actionTypes.SUBMIT_TAX_INFO_FAILED:
      return {
        ...state,
        loading: false,
      }

    case 'RESET_TAX_USER_TYPE':
      return {
        ...state,
        taxesInformation: null,
      }
    case actionTypes.TAX_FORM_FILE_UPLOAD_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.TAX_FORM_FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        FileLink: action.payload?.file_url,
      }
    case actionTypes.TAX_FORM_FILE_UPLOAD_FAILED:
      return {
        ...state,
        loading: false,
        FileLink: null,
      }
    case 'CHANGE_INVESTOR_SETTINGS_PROFILE_ACTIVE_KEY':
      return {
        ...state,
        profileSettingsActiveTabKey: action?.payload,
      }

    case actionTypes.GET_ACCREDITATION_INFO_INIT:
      return {
        ...state,
        isLoadingBankAccount: true,
      }
    case actionTypes.GET_ACCREDITATION_INFO_SUCCESS:
      return {
        ...state,
        isLoadingBankAccount: false,
        get_accreditation_info: action.payload,
      }
    case actionTypes.GET_ACCREDITATION_INFO_FAILED:
      return {
        ...state,
        isLoadingBankAccount: false,
      }

    case actionTypes.SUBMIT_ACCREDITATION_INFO_INIT:
      return {
        ...state,
        isLoadingBankAccount: true,
      }
    case actionTypes.SUBMIT_ACCREDITATION_INFO_SUCCESS:
      return {
        ...state,
        isLoadingBankAccount: false,
        // get_accreditation_info: action.payload,
      }
    case actionTypes.SUBMIT_ACCREDITATION_INFO_FAILED:
      return {
        ...state,
        isLoadingBankAccount: false,
      }
    default:
      return state
  }
}

export default investorFlowControlReducer
